<main class="">
	<header>
		<app-hero-view></app-hero-view>
		<app-header></app-header>
	</header>
	<div class="main-container">
		<div>
			<router-outlet></router-outlet>
		</div>
	</div>
	<footer class="footer guruji-footer">
		<app-footer></app-footer>
	</footer>
</main>