import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DeveloperModel } from 'src/app/shared/models/api/DeveloperModel';
import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';

@Component({
	selector: 'app-developer',
	templateUrl: './developer.component.html',
	styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {

	public today: string | null = null;
	public models: DeveloperModel[] = [];

	constructor(
		private dynamicDataProviderService: DynamicDataProviderService,
		private datePipe: DatePipe) {

		let dt = new Date();
		this.today = this.datePipe.transform(dt, 'yyyy-MM-dd');
	}

	ngOnInit(): void {
		this.dynamicDataProviderService.getDevelopers().subscribe(response => {
			this.models = response;
		});
	}
}