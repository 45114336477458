import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-privacy-policy-page',
	templateUrl: './privacy-policy.page.html',
	styleUrls: ['./privacy-policy.page.scss']
})
export class PrivacyPolicyPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}