import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmConfigModel } from 'src/app/shared/models/api/RealmConfigModel';
import { DynamicDataProviderService } from './dynamic-data-provider.service';

@Injectable({
	providedIn: 'root'
})
export class RealmConfigProviderService {

	//private static model: RealmConfigModel | null = null;

	constructor(private dataProviderService: DynamicDataProviderService) {
	}

	public get(): Observable< RealmConfigModel> {
		//while (RealmConfigProviderService.model === null) {
		//	this.fetchInternal();
		//}
		//return RealmConfigProviderService.model;
		return this.dataProviderService.getRealmConfig();
	}

	//protected fetchInternal(): void {
	//	if (RealmConfigProviderService.model === null) {
	//		this.dataProviderService.getRealmConfig().subscribe(m => {
	//			RealmConfigProviderService.model = m;
	//			console.log('cognitoAppClientId => ' + m.cognitoAppClientId);
	//			console.log('cognitoDomainUri => ' + m.cognitoDomainUri);
	//			console.log('cognitoScopes => ' + m.cognitoScopes);
	//		});
	//	}
	//	if (RealmConfigProviderService.model === null) {
	//		setTimeout(this.fetchInternal, 750);
	//	}
	//}
}