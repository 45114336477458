export enum AppRoutes {
	HOME = 'home',
	DEVELOPER = 'developer',
	DONATION = 'donation',
	PRIVACY_POLICY = 'privacy-policy',

	AUTH_CALLBACK = 'auth-callback',
	AUTH_SIGN_IN = 'auth-sign-in',
	AUTH_SIGN_OUT = 'auth-sign-out',

	TERMS_OF_SERVICE = 'terms-of-service',
	USER_DATA_DELETE = 'user-data-delete', // callback for facebook

	BOOK_VIEW = 'book',
	BULLETIN_VIEW = 'bulletin',
	EVENT_VIEW = 'event',
	GUIDELINE_VIEW = 'guideline',
	PLAYLIST_VIEW = 'playlist',
	SATSANG_VIEW = 'satsang',

	BOOK_INSERT = 'insert/book',
	BULLETIN_INSERT = 'insert/bulletin',
	EVENT_INSERT = 'insert/event',
	GUIDELINE_INSERT = 'insert/guideline',
	PLAYLIST_INSERT = 'insert/playlist',

	BOOK_UPDATE = 'update/book',
	BULLETIN_UPDATE = 'update/bulletin',
	EVENT_UPDATE = 'update/event',
	GUIDELINE_UPDATE = 'update/guideline',
	PLAYLIST_UPDATE = 'update/playlist',

	DEFAULT = ''
}