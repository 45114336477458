import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { GuidelineModel } from 'src/app/shared/models/GuidelineModel';

@Component({
	selector: 'app-guideline-insert',
	templateUrl: './guideline-insert.component.html',
	styleUrls: ['./guideline-insert.component.scss']
})
export class GuidelineInsertComponent implements OnInit {

	public models: GuidelineModel[] = [];

	constructor(private dynamicDataProviderService: DynamicDataProviderService, public translate: TranslateService) {
	}

	ngOnInit(): void {
		this.dynamicDataProviderService.getGuidelines().subscribe({
			next: x => this.models = x,
		});
	}
}