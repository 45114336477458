<div class="bodyContainer">
	<div class="row">
		<div class="col-10 pageHeader">
			View list of current and upcoming Seva (Charity) events by GuruJi ki ESangat
		</div>
		<div class="col-2 float-right">
			
		</div>
	</div>
	<div class="row headerTopMargin">
		<div class="book-list col-6" *ngFor="let list of models; let i=index">

			<div class=" event_info">
				<div>
					<img src="{{ list?.banner }}" height="150" width="350" frameborder="0">
				</div>
				<h6>{{ list?.description }}</h6>
				<h6>{{ list?.date }} - {{ list?.location }}</h6>
			</div>

		</div>
	</div>
</div>