<div class="bodyContainer">
	<div class="row">
		<div class="col-sm-8 guruji-body-home">
			<div>
				<p class="guruji-body-bold">{{ 'home.p-00' | translate }}</p>
				<p>{{ 'home.p-01' | translate }}</p>
				<p>{{ 'home.p-02' | translate }}</p>
				<p>{{ 'home.p-03' | translate }}</p>
				<p>{{ 'home.p-04' | translate }}</p>
				<p>{{ 'home.p-05' | translate }}</p>
				<p>{{ 'home.p-06' | translate }}</p>
				<p>{{ 'home.p-07' | translate }}</p>
				<p>{{ 'home.p-08' | translate }}</p>
				<p>{{ 'home.p-09' | translate }}</p>
			</div>
			<div *ngIf="expandDetails">
				<p>{{ 'home.p-10' | translate }}</p>
				<p>{{ 'home.p-11' | translate }}</p>
				<p>{{ 'home.p-12' | translate }}</p>
				<p>{{ 'home.p-13' | translate }}</p>
				<p>{{ 'home.p-14' | translate }}</p>
				<p>{{ 'home.p-15' | translate }}</p>
				<p>{{ 'home.p-16' | translate }}</p>
				<p>{{ 'home.p-17' | translate }}</p>
				<p>{{ 'home.p-18' | translate }}</p>
				<p>{{ 'home.p-19' | translate }}</p>

				<p>{{ 'home.p-20' | translate }}</p>
				<p>{{ 'home.p-21' | translate }}</p>
				<p>{{ 'home.p-22' | translate }}</p>
				<p>{{ 'home.p-23' | translate }}</p>
				<p>{{ 'home.p-24' | translate }}</p>
				<p>{{ 'home.p-25' | translate }}</p>
				<p>{{ 'home.p-26' | translate }}</p>
				<p class="guruji-body-bold-quote">{{ 'home.p-27' | translate }}</p>
				<p class="guruji-body-bold-quote">{{ 'home.p-28' | translate }}</p>
				<p class="guruji-body-bold-bottom">{{ 'home.p-29' | translate }}</p>

				<p class="guruji-body-bold-bottom">{{ 'home.p-30' | translate }}</p>
				<p>{{ 'home.p-31' | translate }}</p>
				<p>{{ 'home.p-32' | translate }}</p>
				<p>{{ 'home.p-33' | translate }}</p>
				<p>{{ 'home.p-34' | translate }}</p>
				<p>{{ 'home.p-35' | translate }}</p>
				<p>{{ 'home.p-36' | translate }}</p>
				<p>{{ 'home.p-37' | translate }}</p>
				<p>{{ 'home.p-38' | translate }}</p>
				<p>{{ 'home.p-39' | translate }}</p>

				<p>{{ 'home.p-40' | translate }}</p>
				<p>{{ 'home.p-41' | translate }}</p>
				<p>{{ 'home.p-42' | translate }}</p>
				<p>{{ 'home.p-43' | translate }}</p>
				<p>{{ 'home.p-44' | translate }}</p>
				<p>{{ 'home.p-45' | translate }}</p>
				<p>{{ 'home.p-46' | translate }}</p>
				<p>{{ 'home.p-47' | translate }}</p>
				<p>{{ 'home.p-48' | translate }}</p>
				<p>{{ 'home.p-49' | translate }}</p>
			</div>
			<p><a href="javascript:;" (click)="expandMore()">{{ moreText }}</a></p>

			<div>
				<iframe src="./../../../../assets/video/guruji-maharaj.mp4" frameborder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen width="640" height="480"></iframe>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="row">
				<div class="titleHeader">Events</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="event-list">
						<ul>
							<li *ngFor="let list of eventList | slice:0:3; let i=index">
								<div class=" event_info">
									<div class="event_date_dashboard">
										<span>{{ sharedFormService.getDay(list?.time?.begin) }}</span>
										{{ sharedFormService.getMonthName(list?.time?.begin) }}'{{
										sharedFormService.getYear(list?.time?.begin) }}
									</div>
									<h6><a href="javascript:;" tabindex="-1">{{ list.title }}</a></h6>
									<ul>
										<li><i class="far fa-clock"></i> {{
											sharedFormService.getDayWeek(list?.time?.begin) }}
											{{ sharedFormService.getTime(list?.time?.begin) }} -
											{{ sharedFormService.getTime(list?.time?.end) }}</li>
										<li><i class="fas fa-map-marker-alt"></i> {{list?.location?.addressLine1 }} {{
											list?.location?.city}} {{list?.location?.stateCode}}
											{{list?.location?.postalCode}}</li>
									</ul>
									<a href="{{list?.conference?.uri}}" target="_blank"
										*ngIf="sharedFormService.enableJoinBtn(list?.time?.begin, list?.time?.end)"
										class="eventBtn">Join Now <i class="fa fa-caret-right"></i> </a>
								</div>
							</li>
						</ul>
					</div>

				</div>
			</div>

			<div class="row">
				<div class="titleHeader">Bulletin</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="event-list">
						<div class="bulletin_info">
							<ul>
								<li *ngFor="let list of bulletinList; let i=index">
									<div class="row">
										<div class="col-12">
											<h6><a href="javascript:;" tabindex="-1">{{ list.title }}</a></h6>
										</div>

										<div class="col-12">
											{{list?.date}}
										</div>
										<div class="col-12">
											<a href="javascript:;">
												<img [src]="'./../../assets/images/swaroop/'+list?.src"
													style="width: 100%; display: block;" />
											</a>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>