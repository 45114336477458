import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-donation-page',
	templateUrl: './donation.page.html',
	styleUrls: ['./donation.page.scss']
})
export class DonationPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}