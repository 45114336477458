import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppRoutes } from '../../routes/app-routes'
import { AuthTokenStoreService } from '../services/auth-token-store.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	public expandMenu: boolean = false;
	public loggedIn: boolean = false;

	constructor(public translate: TranslateService, public authTokenStoreService: AuthTokenStoreService) {
	}

	ngOnInit(): void {
		this.authTokenStoreService.loggedIn.subscribe(resp => {
			this.loggedIn = resp;
		});
	}

	public get appRoutes(): typeof AppRoutes {
		return AppRoutes;
	}

	toggleLanguage(lang: string): void {
		if (lang === 'en' || lang === 'hi' || lang === 'pa') {
			this.translate.use(lang);
		} else {
			this.translate.use('en');
		}
	}

	onIconClick() {
		if (!this.expandMenu) {
			this.expandMenu = true;
		} else {
			this.expandMenu = false;
		}
	}
}