import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-hero-view',
	templateUrl: './hero-view.component.html',
	styleUrls: ['./hero-view.component.scss']
})
export class HeroViewComponent implements OnInit {

	constructor(public translate: TranslateService) {
	}

	ngOnInit(): void {
	}
}