import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-auth-callback-page',
	templateUrl: './auth-callback.page.html',
	styleUrls: ['./auth-callback.page.scss']
})
export class AuthCallbackPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}