import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { EventModel } from 'src/app/shared/models/api/EventModel';

@Component({
	selector: 'app-event-insert',
	templateUrl: './event-insert.component.html',
	styleUrls: ['./event-insert.component.scss']
})
export class EventInsertComponent implements OnInit {

	public models: EventModel[] = [];

	constructor(private dynamicDataProviderService: DynamicDataProviderService, public translate: TranslateService) {
	}

	ngOnInit(): void {
		// this.dynamicDataProviderService.getEvents().subscribe({
		// 	next: x => this.models = x,
		// });
	}
}