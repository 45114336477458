<div class="bodyContainer">
	<div class="row">
		<div class="col-10 pageHeader">
			Published material on Sangat’s journey and experience with Guru Ji.
		</div>
		<div class="col-2 float-right">
			
		</div>
	</div>
	<div class="row headerTopMargin">
		<div class="col-12 headerTopMargin row" *ngIf="!mobileScreen">
			<div class="book-list col-4" *ngFor="let model of models; let i=index">
				<div class=" event_info">
					<div class="event_date_dashboard">
						<img src="{{ getBookThumbnailUri(model?.id) }}" alt="{{ model?.title }}" height="200" width="150" frameborder="0" />
					</div>
					<h6>{{ model?.title }}</h6>
					<h6 *ngIf='model?.author'>Author: {{ model?.author }}</h6>
					<h6 *ngIf='model?.languageCodes'>Language(s): {{ getLanguages(model?.languageCodes) }}</h6>
					<a href="{{ getBookDownloadUri(model?.id) }}">Download</a>
				</div>
			</div>
		</div>
		<div class="col-12 headerTopMargin row" *ngIf="mobileScreen">
			<div class="book-list col-12" *ngFor="let model of models; let i=index">
				<div class=" event_info">
					<div class="event_date_dashboard">
						<img src="{{ getBookThumbnailUri(model?.id) }}" alt="{{ model?.title }}" height="200" width="150" frameborder="0" />
					</div>
					<h6>{{ model?.title }}</h6>
					<h6 *ngIf='model?.author'>Author: {{ model?.author }}</h6>
					<h6 *ngIf='model?.languageCodes'>Language(s): {{ getLanguages(model?.languageCodes) }}</h6>
					<a href="{{ getBookDownloadUri(model?.id) }}">Download</a>
				</div>
			</div>
		</div>
	</div>
</div>