import { Component, OnInit } from '@angular/core';
import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';

import { DynamicDataProviderService } from './../../../core/services/dynamic-data-provider.service';
import { SharedFormService } from './../../../core/services/shared-form.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public eventList: any[] = [];
	public bulletinList: any[] = [];
	public expandDetails: boolean = false;
	public moreText: string = 'More...';

	constructor(
		public sharedFormService: SharedFormService,
		private dynamicDataProviderService: DynamicDataProviderService,
		public authTokenStoreService: AuthTokenStoreService
	) {
	}

	ngOnInit(): void {
		let today = new Date();
		let min = this.sharedFormService.convertMomentToDateFormat(today, 'YYYYMMDD');
		let max = this.sharedFormService.convertMomentToDateFormat(today.setDate(today.getDate() + 15), 'YYYYMMDD');
		this.dynamicDataProviderService.getEvents(min, max).subscribe({
			next: x => this.eventList = x,
		});
		this.isLoggedIn();
	}

	isLoggedIn() {
		this.authTokenStoreService.loggedIn.next(this.authTokenStoreService.isLoggedIn());
	}

	expandMore() {
		if (this.expandDetails) {
			this.expandDetails = false;
			this.moreText = 'More...';
		} else {
			this.expandDetails = true;
			this.moreText = 'Less...';
		}
	}
}