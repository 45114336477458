import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-auth-sign-in-page',
	templateUrl: './auth-sign-in.page.html',
	styleUrls: ['./auth-sign-in.page.scss']
})
export class AuthSignInPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}