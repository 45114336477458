<div class="bodyContainer">
	<div class="row" *ngIf="!mobileScreen">

		<div class="col-10 pageHeader">
			Sangat’s journey and experience with Guru ji Maharaj.<br/>
			<i>Note: Sangat will need to register and log in to post their experiences with Guru Ji Maharaj.</i></div>
		<div class="col-2 float-right">

			<button *ngIf="loggedIn" pButton class="eventBtn" tooltip="Add New Satsang" icon="fa fa-plus" (click)="addNewESatsang()"
				iconPos="left"></button>
		</div>
	</div>

		<div class="row" *ngIf="mobileScreen">

		<div class="col-8pageHeader">
			Sangat’s journey and experience with Guru ji Maharaj.<br/>
			<i>Note: Sangat will need to register and log in to post their experiences with Guru Ji Maharaj.</i></div>
		<div class="col-4 float-right">

			<button *ngIf="loggedIn" pButton class="eventBtn" tooltip="Add New Satsang" icon="fa fa-plus" (click)="addNewESatsang()"
				iconPos="left"></button>
		</div>
	</div>
	<div class="row event-Satsanglist" *ngIf="!mobileScreen">
			<div class="col-5 event_Satsang" *ngFor="let list of satsangList">
				<h2 class="satsangTitle">{{ list?.title }}</h2>
				<div class="author col-12">{{ sharedFormService.convertMomentToDateFormat(list?.date, 'MMM DD YYYY') }} | {{ list?.author }}</div>
				<div class="satsang-content col-12">{{ list?.detail | slice:0:100 }} ...<a href="javascript:;" (click)="showMore(list.detail)">More</a></div>
			</div>
	</div>

	<div class="row event-Satsanglist" *ngIf="mobileScreen">
		<div class="col-9 event_Satsang" *ngFor="let list of satsangList">
			<h2 class="satsangTitle">{{ list?.title }}</h2>
			<div class="author col-12">{{ sharedFormService.convertMomentToDateFormat(list?.date, 'MMM DD YYYY') }} | {{ list?.author }}</div>
			<div class="satsang-content col-12">{{ list?.detail | slice:0:100 }} ...<a href="javascript:;" (click)="showMore(list.detail)">More</a></div>
		</div>
	</div>

	<!-- Publisher grid -->
	<div class="row publishBottomMargin" *ngIf="userProfile === 'publisher'">
		<p-table [value]="draft" [scrollable]="true" scrollHeight="300px">
			<ng-template pTemplate="header">
				<tr>
					<th>Title</th>
					<th>Author</th>
					<th></th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-list>
				<tr>
					<td>{{list.title}}</td>
					<td>{{list.name}}</td>
					<td>
						{{ list?.detail | slice:0:100 }} ...<a href="javascript:;" (click)="showMore(list.detail)">More</a>
					</td>
					<td>
						<a href="javascript:;" class="eventBtn" (click)="onPublishSatsang(list)">Publish</a>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<div [formGroup]="satsangFormGroup">
	<p-dialog [header]="'Add New Event'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '50em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="addSatsangModel">
		<div class="container" [formGroup]="satsangFormGroup" *ngIf="satsangFormGroup">
						<div class="row">
				<div class="col-12 input-spacing">
					<div class="label-style">Title</div>
					<input type="text" inputId="basic" formControlName="title" class="form-control">
				</div>
			</div>
			<div class="row">
				<div class="col-12 input-spacing">
					<div class="label-style">Satsang</div>
					<textarea type="text" inputId="basic" rows="10" formControlName="detail" class="form-control"></textarea>
				</div>
			</div>

		</div>
		<p-footer>
			<div class="col-12 row">
				<div class="col-4 text-right noPadding-right">
					<button pButton type="button" label="CANCEL" (click)="cancelSatsang()"
						class="ui-button-raised"></button>
					<button pButton type="button" label="SUBMIT" (click)="submitSatsang()"
						class="prime-btn-primary-modal ui-button-raised"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>
</div>

<div *ngIf="showSatsang">
		<p-dialog [header]="'Satsang'" appendTo="body" [contentStyle]="{'max-height':'25em'}" [style]="{width: '80em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="showSatsang">
				<div class="container">
						<div class="row">
								{{ satsangDetails }}
						</div>
				</div>
		</p-dialog>
</div>

<p-dialog header="Success" [(visible)]="displaySuccess" [style]="{width: '20vw'}">
	{{message}}
</p-dialog>