import { Component, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LanguageProviderService } from './core/services/language-provider.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'guruji-angular-ui';

	constructor(
		public ref: ChangeDetectorRef
		, public translate: TranslateService
		, public language: LanguageProviderService
	) {
		language.setup(translate);
	}

	ngOnInit() {
		// https://stackoverflow.com/questions/48739768/host-angular-app-on-iis-redirect-to-root-and-force-https
		// if (location.protocol === 'https:') {
		// window.location.href = location.href.replace('https://', 'http://');
		// }
	}

	ngOnDestroy() {
	}
}