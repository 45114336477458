import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-guideline-insert-page',
	templateUrl: './guideline-insert.page.html',
	styleUrls: ['./guideline-insert.page.scss']
})
export class GuidelineInsertPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}