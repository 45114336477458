<div class="bodyContainer">
	<div class="row">
		<div class="col-12 headerTopMargin">
			<p-tabView>
				<p-tabPanel header="Basics">
					<div class="gudelineScroll">
						<p class="guruji-body-bold">SHABAD GURBANI</p>
					<p>Shabad Gurbani should be played for 1-1\2 hours (90 Minutes) Excluding Mantra Jaap and Aarti.
						Everyone is
						requested to include most of the Shabads from Gurbani along with at least one Gurbani with
						Vyakhya so
						that
						Guruji’s messages can be effectively conveyed to the Sangat. Please do not chant Shabads loudly
						as it
						can
						disturb Sangat sitting next to you.</p>

					<p>Links to a partial list of Shabads played in Guruji’s Darbar is included at the end of this page
						for
						reference.</p>

					<p class="guruji-body-bold">MANTRA JAAP</p>
					<p>To maintain connection with Guruji, all Sangat is encouraged to chant Guruji’s Mantra Jaap along
						with the
						recording which is played (recording will be played to maintain consistency)</p>

					<p class="guruji-body-bold">AARTI FORMAT</p>
					<p>It is recommended that the family organizing the Satsang should start Guruji’s Aarti followed by
						the
						elders
						and others. Please stand where you are and do Guruji’s Aarti. The Aarti to be played will be–
						“Shivji Ki
						Aarti” (5 minutes) Please note that couples/family members don’t necessarily need to do Guruji’s
						Aarti
						together. This will help avoid disruptions in case family members are not sitting together.
						Everyone
						receives equal blessings irrespective of who you do the Aarti with.</p>

					<p class="guruji-body-bold">SATSANG SHARING</p>
					<p>Guruji always encouraged his Sangat to share their personal experiences with everyone on how he
						has
						blessed
						him/her. Hence, it is highly recommended that whoever is sharing Satsang stick to their
						experiences in
						short
						(10 – 15 mins) and not give spiritual/religious long lectures. This will allow the entire Sangat
						to get
						connected to our Guruji in their way and give equal chance to all to share their Satsangs as
						well. In
						instances where total Satsang sharing exceeds 30 minutes, start serving Langar Prasad alongside
						Satsang
						sharing.</p>

					<p class="guruji-body-bold">PRASHAD</p>
					<p>Chai prashad is served to sangat during shabad kirtan. The host may serve very light snack along
						with
						(please keep in mind that langar prasad will be served after satsang and sangat should not feel
						overloaded
						with prasad).</p>

					<p>Langar prasad should typically consist of sabzi, daal, roti and kada (halwa or a piece of sweet).
						We
						understand its Guruji’s blessed food, however, everyone needs to finish as we cannot leave any
						leftovers
						and
						with too much food sometimes Sangat may struggle in finishing it.</p>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Satsang Discipline Guidelines">
					<div class="gudelineScroll">
						<ul>
							<li>The use of Mobile Phones is strictly not allowed in the Satsang Hall. If you wish to take
								any pictures or take a call, you must step out of the Satsang Hall. If you wish to take
								pictures of the Darbar, you may do that after having the Langar Prasad ensuring minimum
								disturbance.</li>
	
							<li>Sangat with young kids are requested to sit at the back of the Satsang Hall or in another
								room, so they can take care of the kids in an efficient manner without disturbance in the
								Hall.</li>
	
							<li>All Sangat is requested to take ‘Aagya’ from Guruji and leave immediately after having
								Langar Prasad. We believe that Guruji is present in the Satsang Hall right from the start
								till the end, hence we should all refrain from talking and chatting in the Hall. Should you
								wish to talk and socialize, you may do so outside the Venue.</li>
	
							<li>Guruji always instructed his Sangat to go home directly after the Satsang so that they can
								carry the blessings to others in their home,</li>
	
							<li>Please refrain all children below 12 years of age to do any ‘Sewa’ at the Satsang.. If
								children above the age of 12 wish to do any ‘Sewa’, they should be encouraged to do it only
								within the Satsang Hall, under the guidance of adult sewadar.</li>
	
							<li>It is also integral to look for Health and Safety aspects. Please do not use wax candles
								excessively and ensure that they are safe and stable especially for people coming to Bow
								down and for Bhog etc.</li>
	
							<li>All Sangat is encouraged to sit in rows after bowing their heads in front of our Guruji.
							</li>
	
							<li>Please refrain from searching your friends/family members and moving around as this disrupts
								the Satsang Hall. Whoever comes late should ideally take a seat in the last row so that the
								Sewadars can serve Prasad in an orderly manner.</li>
						</ul>
					</div>
					
				</p-tabPanel>
				<p-tabPanel header="Hosts">
					<div class="gudelineScroll">
						<ul>
							<li>First of all, the host should analyze if he/she is ready to host a Sangat. This is because
								Guruji’s Satsang is very different from a normal chowki, kirtan, Jagran, or Sai Sandhya.
								Guruji’s Satsang is more disciplined and needs more attention to the details. Our advice
								would be to attend 5-6 satsangs and observe everything before hosting one.</li>
	
							<li>Hosting a Satsang is a celebration because you are inviting Guruji to your place with
								Guruji’s Pariwar. So, you should be very happy, excited, and welcoming!</li>
	
							<li>The date and time you choose to host Satsang should be convenient for you and your whole
								family.</li>
	
							<li>Try to keep light snacks for chai prasad i.e. one snack with tea (samosa or dhokla) and
								simple and light food for langar Prasad which means basic dal, sabzi, chapatti and one
								sweet. Please try to cook langar prashad at home under very clean and pious environment.
							</li>
	
							<li>The host should mainly focus on preparing the house to welcome Guruji, decorating Guruji's
								Darbar, and preparing his comfortable singhasan. You can use any Chunni is for decorations,
								mostly Chola is shared with the Sangat. Use flowers, Dias, candles, or any other economical
								items for decorations. For ideas, you can look at our Facebook groups. Decorate it with your
								love for Guruji along with Shradha and Shukrana.</li>
	
							<li>If you are inviting guests, please inform them firmly to come on time and inform them that
								their mobiles should be off or on silent mode. They should maintain noble silence and should
								finish Jal prasad, chai prasad, and langar Prasad.</li>
	
							<li>The guests should not bring any food items with them to distribute, if they still bring and
								you want to serve please do not make it part of the pre-decided langar. You can serve it
								later by asking everyone if they want that additional item? However, it should be
								discouraged.</li>
	
							<li>The host should keep in mind that prasad should be in the limit which can be easily finished
								by Sangat.</li>
	
							<li>The host should maintain the DISCIPLINE about timings, the revered JOT should be lighted in
								time. If it is 7.00, it should be 7.00. It is believed that Guruji attends every Satsang,
								and is there at the designated time. Even if all family members are not present, never mind.
								Anyone among Sangat who is present at that time can light.</li>
	
							<li>Please do not disturb the AKHAND JYOT by checking the oil in it again and again. Please make
								jot by keeping 2-3 hrs. in mind. Place that much oil in the jot so that it remains lit for
								that much time.</li>
	
							<li>Guruji should be offered Jal prasad and then Chai prasad with covered heads. Guruji's steel
								utensils should be cleaned and ready before the Satsang starts. Please do not serve Guruji's
								Bhog in disposable.</li>
	
							<li>The host should finish all the chores and distribute Sewa in such a way that there should be
								no hindrance while doing Sewa, and the host can devote time with Guruji</li>
	
							<li>Bathrooms should be clean and there should be a pair of slippers, hand towels, tissues, and
								of course hand wash.</li>
	
							<li>Sewa should be done in a very quiet manner by the Sangat or family members and not by
								servants.</li>
	
							<li>Keep a playlist of what you want to play ready. YouTube playlist with ads and pauses should
								be avoided as they create a lot of disruption. It is very important to play shabads from
								Gurbani. of Satsang and not any bhajans of your choice, as Guruji always said that, there is
								nothing more pure on earth than Gurbani, you will only get your answers by listening to the
								shabads. We should follow religiously the way it is done in Bade Mandir.</li>
	
							<li>After the Satsang finished, fold Chola and other accessories properly. Clean utensils and
								pack them properly.</li>
						</ul>
					</div>
					
				</p-tabPanel>
				<p-tabPanel header="Rules and Guidelines for Sangat ">
					<div class="gudelineScroll">
						<ul>
							<li>Please try to reach on time during Satsang, try to reach 15 minutes before the Satsang.</li>
	
							<li>Wish or greet the host only and only after the Satsang otherwise it can disturb the host
								along with the Sangat sitting next to the host.</li>
	
							<li>The Sangat reaching late is requested to sit at the back and do not go near the Darbar to
								bow your head, because that disturbs the people who are meditating and connecting with
								Guruji!</li>
	
							<li>If you have not been assigned Sewa to pick up cups please leave them there</li>
	
							<li>If you are bringing children along, make sure they sit next to you and keep them busy and
								quiet.</li>
	
							<li>Whoever uses the washroom must maintain cleanliness. Yeh Bhi Ek Sewa Hai DUSRAE Ko Karni
								Parti Hai</li>
	
							<li>Please do not start packing the leftover langar prasad unless the host offers it to you.
							</li>
						</ul>
					</div>
					
				</p-tabPanel>
				<p-tabPanel header="List of Shabads from Rma Behl Aunty">
					<div class="gudelineScroll"></div>
				</p-tabPanel>
			</p-tabView>

		</div>
	</div>
</div>