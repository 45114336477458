import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-event-insert-page',
	templateUrl: './event-insert.page.html',
	styleUrls: ['./event-insert.page.scss']
})
export class EventInsertPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}