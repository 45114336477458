import { Component, OnInit } from '@angular/core';

import { UriModel } from 'src/app/shared/models/UriModel';

@Component({
	selector: 'app-donation-component',
	templateUrl: './donation.component.html',
	styleUrls: ['./donation.component.scss']
})
export class DonationComponent implements OnInit {

	ngOnInit(): void {
	}

	getSquareUri(): string {
		let uri: UriModel = new UriModel('https://checkout.square.site/merchant/MLP89WM2EKDNT/checkout/VD6GB6EYWNBJ7TKPSKWJWKF6?src=embed');
		return uri.trailingSlashNo;
	}
}