<div class="bodyContainer">
	<div class="row" *ngIf="!mobileScreen">
		<div class="col-10 pageHeader">
			View list of current and upcoming Satsangs (Mandir, Home and Virtual)<br/>
			<i>Note –Sangat will need to register and log in to post your Satsang on website.</i>
		</div>
		<div class="col-2 float-right">
			<button pButton class="eventBtn calendarIconPadding" tooltip="Select Month" icon="fa fa-calendar"
				(click)="addMonthModal=true" iconPos="left"></button>
			<button pButton *ngIf="loggedIn" class="eventBtn" tooltip="Add New Event" icon="fa fa-plus" (click)="addNewEvent()"
				iconPos="left"></button>
		</div>
	</div>

	<div class="row" *ngIf="mobileScreen">

		<div class="col-8">
			View list of current and upcoming Satsangs (Mandir, Home and Virtual)
			<br />
			<i>Note –Sangat will need to register and log in to post your Satsang on website.</i>
		</div>
		<div class="col-4 float-right">
			<button pButton class="eventBtn calendarIconPadding" tooltip="Select Month" icon="fa fa-calendar"
				(click)="addMonthModal=true" iconPos="left"></button>
			<button pButton *ngIf="loggedIn" class="eventBtn" tooltip="Add New Event" icon="fa fa-plus" (click)="addNewEvent()"
				iconPos="left"></button>
		</div>
	</div>

	<div class="row headerTopMargin">
		<div class="col-12 headerTopMargin">
			<div *ngIf="!mobileScreen" class="calendar row text-center">
				<div class="col-4"></div>
				<div class=" col-1 text-center">
					<a href="javascript:;" (click)="onSelectMonth(prevMonthNo, currentYear)"
						*ngIf="currentMonthNo !== 0"><i class="fa fa-backward"></i></a>
				</div>

				<div class="calendar-btn year-btn col-2">
					<span>{{ currentMonth }} {{ currentYear }}</span>
				</div>

				<div class=" col-1 text-center">
					<a href="javascript:;" (click)="onSelectMonth(nextMonthNo, currentYear)"
						*ngIf="currentMonthNo !== 11"><i class="fa fa-forward"></i></a>
				</div>
				<div class="col-4"></div>
				<div class="calendar-dates col-12">
					<div class="days">
						<div *ngFor="let weekdayModel of weekdayModels" class="day label"> {{weekdayModel.name}}</div>
						<div class="clear"></div>
					</div>

					<div id="calendarDays" class="days calendar-dates col-12">
						<span *ngFor="let list of week1" class="day">{{ list.date }}
							<div *ngFor="let slist of list.satsang; let x = index" (click)="onDetailSatsang(slist)"
								[class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
								sharedFormService.getTime(slist?.time?.begin) }} -
								{{ sharedFormService.getTime(slist?.time?.end) }}
							</div>
						</span>
					</div>
					<div id="calendarDays" class="days calendar-dates col-12">
						<span *ngFor="let list of week2" class="day">{{ list.date }}
							<div *ngFor="let slist of list.satsang; let x = index" (click)="onDetailSatsang(slist)"
								[class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
								sharedFormService.getTime(slist?.time?.begin) }} -
								{{ sharedFormService.getTime(slist?.time?.end) }}
							</div>
						</span>
					</div>
					<div id="calendarDays" class="days calendar-dates col-12">
						<span *ngFor="let list of week3" class="day">{{ list.date }}
							<div *ngFor="let slist of list.satsang; let x = index" (click)="onDetailSatsang(slist)"
								[class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{ sharedFormService.getTime(slist?.time?.begin) }} -
								{{ sharedFormService.getTime(slist?.time?.end) }}</div>
						</span>
					</div>
					<div id="calendarDays" class="days calendar-dates col-12">
						<span *ngFor="let list of week4" class="day">{{ list.date }}
							<div *ngFor="let slist of list.satsang; let x = index" (click)="onDetailSatsang(slist)"
								[class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{ sharedFormService.getTime(slist?.time?.begin) }} -
								{{ sharedFormService.getTime(slist?.time?.end) }}</div>
						</span>
					</div>
					<div id="calendarDays" class="days calendar-dates col-12">
						<span *ngFor="let list of week5" class="day">{{ list.date }}
							<div *ngFor="let slist of list.satsang; let x = index" (click)="onDetailSatsang(slist)"
								[class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{ sharedFormService.getTime(slist?.time?.begin) }} -
								{{ sharedFormService.getTime(slist?.time?.end) }}</div>
						</span>
					</div>
				</div>
			</div>

			<div *ngIf="mobileScreen" class="calendar-mobile row">
				<div class="calendar-btn month-btn col-12 text-center">
					<a href="javascript:;" (click)="onSelectMonth(prevMonthNo, currentYear)"
						*ngIf="currentMonthNo !== 0"><i class="fa fa-backward"></i></a> <span class="weekPadding">{{
						currentMonth }} {{ currentYear }}</span>
					<a href="javascript:;" (click)="onSelectMonth(nextMonthNo, currentYear)"
						*ngIf="currentMonthNo !== 11"><i class="fa fa-forward"></i></a>
				</div>

				<div class="col-12 text-center">
					<a href="javascript:;" (click)="prevWeek()" *ngIf="currentWeek !== 1"><i
							class="fa fa-backward"></i></a> <a href="javascript:;" class="weekPadding">Week</a>
					<a href="javascript:;" (click)="nextWeek()" *ngIf="currentWeek !== 5"><i
							class="fa fa-forward"></i></a>
				</div>

				<div class="calendar-dates col-12">
					<div class="days row">
						<div class="col-6 row">
							<div *ngFor="let weekdayModel of weekdayModels" class="day label col-12">
								{{weekdayModel.name}}</div>
							<div class="clear"></div>
						</div>

						<div class="col-6 row">
							<div id="calendarDays" class="days calendar-dates row" *ngIf="displayWeek1">
								<div *ngFor="let list of week1" class="day col-12">{{ list.date }}
									<div *ngFor="let slist of list.satsang; let x = index"
										(click)="onDetailSatsang(slist)" [class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
										sharedFormService.getTime(slist?.time?.begin) }} -
										{{ sharedFormService.getTime(slist?.time?.end) }}
									</div>
								</div>
							</div>
							<div id="calendarDays" class="days calendar-dates row" *ngIf="displayWeek2">
								<span *ngFor="let list of week2" class="day col-12">{{ list.date }}
									<div *ngFor="let slist of list.satsang; let x = index"
										(click)="onDetailSatsang(slist)" [class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
										sharedFormService.getTime(slist?.time?.begin) }} -
										{{ sharedFormService.getTime(slist?.time?.end) }}
									</div>
								</span>
							</div>
							<div id="calendarDays" class="days calendar-dates row" *ngIf="displayWeek3">
								<span *ngFor="let list of week3" class="day col-12">{{ list.date }}
									<div *ngFor="let slist of list.satsang; let x = index"
										(click)="onDetailSatsang(slist)" [class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
										sharedFormService.getTime(slist?.time?.begin) }} -
										{{ sharedFormService.getTime(slist?.time?.end) }}</div>
								</span>
							</div>
							<div id="calendarDays" class="days calendar-dates row" *ngIf="displayWeek4">
								<span *ngFor="let list of week4" class="day col-12">{{ list.date }}
									<div *ngFor="let slist of list.satsang; let x = index"
										(click)="onDetailSatsang(slist)" [class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
										sharedFormService.getTime(slist?.time?.begin) }} -
										{{ sharedFormService.getTime(slist?.time?.end) }}</div>
								</span>
							</div>
							<div id="calendarDays" class="days calendar-dates row" *ngIf="displayWeek5">
								<span *ngFor="let list of week5" class="day col-12">{{ list.date }}
									<div *ngFor="let slist of list.satsang; let x = index"
										(click)="onDetailSatsang(slist)" [class]="'satsangList'+x" [ngClass]="{ 'recurring-satsang' : slist?.recurring }">{{
										sharedFormService.getTime(slist?.time?.begin) }} -
										{{ sharedFormService.getTime(slist?.time?.end) }}</div>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

	<!-- Publisher grid -->
	<div class="row publishBottomMargin" *ngIf="userProfile === 'publisher'">
		<p-table [value]="draft" [scrollable]="true" scrollHeight="300px">
			<ng-template pTemplate="header">
				<tr>
					<th>Title</th>
					<th>Author</th>
					<th>Date</th>
					<th>Time</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-event>
				<tr>
					<td>{{event.title}}</td>
					<td>{{event.name}}</td>
					<td>{{ sharedFormService.convertMomentToDateFormat(event?.time?.begin, 'DD-MMM-YYYY')}}</td>
					<td>{{ sharedFormService.getDayWeek(event?.time?.begin) }}
						{{ sharedFormService.getTime(event?.time?.begin) }} -
						{{ sharedFormService.getTime(event?.time?.end) }}</td>
					<td>
						<a href="javascript:;" class="eventBtn" (click)="onPublishEvent(event)">Publish</a>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

</div>

<!-- Add Event -->
<div [formGroup]="eventFormGroup">
	<p-dialog [header]="'Add New Event'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '50em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="addEventModal">
		<div class="container" [formGroup]="eventFormGroup" *ngIf="eventFormGroup">
			<div class="row">
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Event <span class="alert-message">*</span></div>
						<input id="float-input" type="text" maxlength="50" class="popupInput100 form-control"
							formControlName="eventName">
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['eventName'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Date <span class="alert-message">*</span></div>
						<input type="date" inputId="basic" formControlName="date" class="form-control"
							placeholder="mm/dd/yyyy">
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['date'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
				<div class="col-4 input-spacing">
					<div>
						<div class="label-style">Start Time <span class="alert-message">*</span></div>
						<select formControlName="startTime" class="form-control">
							<option *ngFor="let list of timing" [value]="list.value">{{ list.label }}</option>
						</select>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['startTime'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
				<div class="col-4 input-spacing">
					<div>
						<div class="label-style">AM/PM <span class="alert-message">*</span></div>
						<select formControlName="ampmStart" class="form-control" placeholder="Please select">
							<option value="AM">AM</option>
							<option value="PM">PM</option>
						</select>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['ampmStart'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>

				<div class="col-4 input-spacing">
					<div>
						<div class="label-style">Duration <span class="alert-message">*</span></div>
						<select formControlName="endTime" class="form-control">
							<option value="1">1</option>
							<option value="1.5">1.5</option>
							<option value="2">2</option>
							<option value="2.5">2.5</option>
							<option value="3">3</option>
							<option value="3.5">3.5</option>
							<option value="4">4</option>
							<option value="4.5">4.5</option>
							<option value="5">5</option>
						</select>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['endTime'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Location</div>
						<select formControlName="satsangType" class="form-control" (change)="onSelectLocation($event)">
							<option value="1">Home</option>
							<option value="2">Mandir</option>
							<option value="3">Virtual</option>
						</select>
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Live Satsang Link</div>
						<input type="text" inputId="basic" formControlName="conferenceLink" class="form-control">
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Live Satsang Password</div>
						<input type="text" inputId="basic" formControlName="conferencePassword" class="form-control">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Address <span class="alert-message">*</span></div>
						<input id="float-input" type="text" class="popupInput100 form-control" maxlength="30"
							formControlName="address" pInputText>
					</div>
					<div>
						<div class="label-style">Address 2 </div>
						<input id="float-input" type="text" class="popupInput100 form-control" maxlength="30"
							formControlName="address2" pInputText>
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">City <span class="alert-message">*</span></div>
						<input id="float-input" type="text" class="popupInput100 form-control" maxlength="30"
							formControlName="city" pInputText>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['city'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Country <span class="alert-message">*</span></div>
						<select class="popupInput100 form-control" formControlName="country"
							placeholder="Select country" (change)="onSelectCountry($event.target)">
							<option *ngFor="let countryModel of countryModels" value="{{countryModel.code}}">
								{{countryModel.name}}</option>
						</select>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['country'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">State <span class="alert-message">*</span></div>
						<select class="popupInput100 form-control" formControlName="state"
							placeholder="Select state/province">
							<option *ngFor="let stateModel of stateModels" value="{{stateModel.code}}">
								{{stateModel.name}}</option>
						</select>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['state'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>

				<div class="col-12 input-spacing">
					<div>
						<div class="label-style">Zip/Postal Code <span class="alert-message">*</span></div>
						<input id="float-input" type="text" maxlength="6" class="form-control" formControlName="zip"
							pInputText>
					</div>
					<div class="alert-message" *ngIf="!eventFormGroup.controls['zip'].valid && invalidData">
						<span>This field is required</span>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12 input-spacing">
					<div class="label-style">Notes</div>
					<textarea type="text" inputId="basic" formControlName="notes" class="form-control"></textarea>
				</div>
			</div>

		</div>
		<p-footer>
			<div class="col-12 row">
				<div class="col-8"><a href="javascript:;" (click)="guidelinesPopup = true">Guidelines</a></div>
				<div class="col-4 text-right noPadding-right">
					<button pButton type="button" label="CANCEL" (click)="cancelEvent()"
						class="ui-button-raised"></button>
					<button pButton type="button" label="SUBMIT" (click)="submitEvent()"
						class="prime-btn-primary-modal ui-button-raised"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>
</div>

<!--month and year picker-->
<div [formGroup]="monthFormGroup">
	<p-dialog [header]="'Selct Month'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '50em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="addMonthModal">
		<div class="container" [formGroup]="monthFormGroup">
			<div class="row">
				<div class="col-6 input-spacing">
					<div>
						<div class="label-style">Month*</div>
						<select formControlName="month" class="form-control">
							<option *ngFor="let monthModel of monthModels" [value]="monthModel.value">{{ monthModel.name
								}}</option>
						</select>
					</div>
				</div>
				<div class="col-6 input-spacing">
					<div>
						<div class="label-style">Year*</div>
						<select formControlName="year" class="form-control">
							<option *ngFor="let list of years; let i = index" [value]="list">{{ list }}</option>
						</select>
					</div>
				</div>
			</div>

		</div>
		<p-footer>
			<div class="col-12 row">
				<div class="col-8"></div>
				<div class="col-4 text-right noPadding-right">
					<button pButton type="button" label="CANCEL" (click)="cancelPicker()"
						class="ui-button-raised"></button>
					<button pButton type="button" label="SUBMIT" (click)="submitPicker()"
						class="prime-btn-primary-modal ui-button-raised"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>
</div>

<!--event details-->
<div *ngIf="eventDetailsModal">
	<p-dialog [header]="'Event Details'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '50em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="eventDetailsModal">
		<div class="container">
			<div class="row">
				<div class="col-12 input-spacing">

					<div class="label-style-satsang">Title</div>
					{{ selectSatsangDetails?.title }}
				</div>
			</div>
			<div class="row">
				<div class="col-6 input-spacing">

					<div class="label-style-satsang">Date</div>
					{{ sharedFormService.convertMomentToDateFormat(selectSatsangDetails?.time?.begin, 'DD-MMM-YYYY')}}
				</div>
				<div class="col-6 input-spacing">

					<div class="label-style-satsang">Timings</div>
					<i class="far fa-clock"></i> {{ sharedFormService.getDayWeek(selectSatsangDetails?.time?.begin) }}
					{{ sharedFormService.getTime(selectSatsangDetails?.time?.begin) }} -
					{{ sharedFormService.getTime(selectSatsangDetails?.time?.end) }}
				</div>

			</div>
			<div class="row">
				<div class="col-6 input-spacing">
					<div class="label-style-satsang">Address</div>
					<i class="fas fa-map-marker-alt"></i> {{selectSatsangDetails?.location?.addressLine1 }} {{
					selectSatsangDetails?.location?.city}} {{selectSatsangDetails?.location?.stateCode}}
					{{selectSatsangDetails?.location?.postalCode}}
				</div>
			</div>
			<div class="row">
				<div class="col-6 input-spacing">
					<div class="label-style-satsang">Link</div>
					<a href="{{selectSatsangDetails?.conference.uri}}" target="_blank"
										*ngIf="sharedFormService.enableJoinBtn(selectSatsangDetails?.time?.begin, selectSatsangDetails?.time?.end)"
										class="eventBtn">Join Now <i class="fa fa-caret-right"></i> </a>
				</div>
				<div class="col-6 input-spacing">
					<div class="label-style-satsang">Password</div>
					{{selectSatsangDetails?.conference?.password}}
				</div>
			</div>

		</div>
		<p-footer>
			<div class="col-12 row">
				<div class="col-8"></div>
				<div class="col-4 text-right noPadding-right">
					<button pButton type="button" label="CANCEL" (click)="eventDetailsModal=false"
						class="ui-button-raised"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>
</div>

<!--guidelines popup-->
<div *ngIf="guidelinesPopup">
	<p-dialog [header]="'Guidelines'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '90em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="guidelinesPopup">
		<app-guideline-view></app-guideline-view>
		<p-footer>
			<div class="col-12 row">
				<div class="col-8"></div>
				<div class="col-4 text-right noPadding-right">
					<button pButton type="button" label="CANCEL" (click)="guidelinesPopup=false"
						class="ui-button-raised"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>
</div>

<p-dialog header="Success" [(visible)]="displaySuccess" [style]="{width: '20vw'}">
	{{message}}
</p-dialog>