<div>
	<div class="row">
		<div class="col-4 footer-text1 guruji-footer"><b>{{ 'footer.text-1' | translate }}</b></div>
		<div class="col-4 footer-text2 guruji-footer"><b>{{ 'footer.text-2' | translate }}</b></div>
		<div class="col-4 footer-text2 guruji-footer float-right">
			<b>
				{{ 'footer.text-3' | translate }} <a href="javascript:;" class="footer-disclaimer"
					(click)="disclaimerPopup = true">{{ 'footer.text-4' | translate }}</a>
				|
				<a class="footer-disclaimer" routerLink="{{ this.appRoutes.PRIVACY_POLICY }}">{{ 'footer.privacy-policy'
					| translate }}</a>
			</b>
		</div>
	</div>
</div>

<div *ngIf="disclaimerPopup">
	<p-dialog [header]="'Disclaimer'" appendTo="body" [contentStyle]="{'min-height':'6em'}" [style]="{width: '80em'}"
		[baseZIndex]="10000" [positionTop]="300" [closable]="true" [resizable]="false" [modal]="true"
		[(visible)]="disclaimerPopup">
		<div class="container">
			<div class="row">
				<p>Disclaimer: This video is not being used for any money-making purposes and is only used for virtual
					Religious Gatherings (Satsangs). This Video does not intend to earn any money or monetize in any
					manner and is a means to connect devotees in a prayer of our God (Guruji). The lyrics of songs being
					played are coming from the Sikhism Holy Book of ‘Shri Guru Granth Sahib Ji’ and the songs are just
					hymn being sung in the prescribed ‘Raga’ or ‘Symphony’ as written in the holy scripture. The songs
					being used are in ‘Fair Use’ similar to the Fair Use of original lyrics by the Singer from the Holy
					Scripture. The devotees want to listen to Melodious Hymns, sitting in a room, and connect to God.
					Such, no manner does the Sangat (Devotees) want to do anything with the copyrighted material. We
					want our kids to also get connected to our roots. Many children are devotees who also connect to our
					roots of our Guru and keenly listen to all the Shabad (song from Holy Book of Sikhism - Shri Guru
					Granth Sahib Ji) and sit with their parents in Satsangs.</p>
				<p>All Individuals (Sangats) who use this platform gives us the consent to distribute only their
					personal experiences with the Divine, with Our Guruji Maharaj Sangat Pariwar, All Content Created
					will be Solely IP of “Guruji Ki ESangat, Inc.” and Sangat Pariwar Associated with “Guruji Ki
					ESangat, Inc.”. Any Content created by Individuals (Sangats) after the music stops in part or full
					or edited version used, without written Consent from “Guruji Ki ESangat, Inc.” is not permitted.</p>
				<p><strong>DISCLAIMER</strong>: This Following Audio/Video is Strictly meant for Nonprofits & Activism.
					We Do Not Wish to make any Commercial Use of this & Intended to Showcase the Creativity Of the
					Artist Involved. ONLY FOR PROMOTIONAL PURPOSES. We Don’t Hold Any Copyrights Of The Audio, All
					Rights Reserved By The Respective Owners</p>
				<p><strong>NO COPYRIGHT INFRINGEMENT INTENDED.</strong> As per 3rd Section of Fair use guidelines
					Borrowing small bits of material from an original work is more likely to be considered fair use.
					Copyright Disclaimer Under Section 107 of the Copyright Act 1976, allowance is made for fair use</p>
				<p><strong>Copyright Disclaimer</strong>: Under Section 107 of the Copyright Act 1976, allowance is made
					for “Fair Use” for purposes such as criticism, comment, news reporting, teaching, scholarship and
					research. Fair use is a use permitted by copyright statute that might otherwise be infringing.
					Non-profit, educational or personal use tips the balance in favor of fair use.</p>
				<p><strong>Disclaimer</strong>: Any files transmitted with it are confidential and intended solely for
					the use of the individual or entity to whom they are addressed. If you have received this email in
					error please notify the system manager & the sender. This message contains confidential information
					and is intended only for the individual named. If you are not the named addressee you should not
					disseminate, distribute or copy this e-mail. Please notify the sender immediately by e-mail if you
					have received this e-mail by mistake and delete this e-mail from your system. If you are not the
					intended recipient you are notified that disclosing, copying, distributing or taking any action in
					reliance on the contents of this information is strictly prohibited. Copyright are Reserved with
					Guruji Ki ESangat, Inc. [𝗡𝗼𝘁 𝗳𝗼𝗿 𝗣𝗿𝗼𝗳𝗶𝘁 𝗢𝗿𝗴𝗮𝗻𝗶𝘇𝗮𝘁𝗶𝗼𝗻 𝗦𝗲𝗿𝘃𝗶𝗻𝗴
					𝗦𝗮𝗻𝗴𝗮𝘁 𝗣𝗮𝗿𝗶𝘄𝗮𝗿]</p>

				<p><strong>Disclaimer</strong>: Any files transmitted with it are confidential and intended solely for
					the use of the individual or entity to whom they are addressed. If you have received this email in
					error please notify the system manager & the sender. This message contains confidential information
					and is intended only for the individual named. If you are not the named addressee you should not
					disseminate, distribute or copy this e-mail. Please notify the sender immediately by e-mail if you
					have received this e-mail by mistake and delete this e-mail from your system. If you are not the
					intended recipient you are notified that disclosing, copying, distributing or taking any action in
					reliance on the contents of this information is strictly prohibited. Copyright are Reserved with
					Guruji Ki ESangat, Inc. [𝗡𝗼𝘁 𝗳𝗼𝗿 𝗣𝗿𝗼𝗳𝗶𝘁 𝗢𝗿𝗴𝗮𝗻𝗶𝘇𝗮𝘁𝗶𝗼𝗻 𝗦𝗲𝗿𝘃𝗶𝗻𝗴
					𝗦𝗮𝗻𝗴𝗮𝘁 𝗣𝗮𝗿𝗶𝘄𝗮𝗿]</p>
				<p><strong>Disclaimer</strong>: Any files referenced on the website are solely for personal consumption
					of the devotee(s) and these is not indent to be distributed for profit The original creators/authors
					are solely owning any copyright to it. If any content creator/author has any issue, you can send
					email YourSewadar</p>
			</div>
		</div>
	</p-dialog>
</div>