<div class="bodyContainer">
	<div class="row">
		<div class="col-10 pageHeader">
			Donation link for Sangat wishing to donate towards the Seva.
		</div>
		<div class="col-2 float-right">
			
		</div>
	</div>
	<div class="row headerTopMargin">
		<div class="col-12 text-center">
			<p class="site guruji-header-link">Guruji Ki ESangat</p>
			<p><img src="../../../../assets/images/swaroop/guruji.jpg" class="gurujiSwaroop"/></p>
			<a target="_blank" href="{{ getSquareUri() }}"
				style=" display: inline-block; line-height: 48px; height: 48px; color: #ffffff; min-width: 165px; background-color: #F17024; border-radius: 4px; text-align: center; box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;">Donate</a>
			<p class="site guruji-header-link">Guruji Ki ESangat, Inc.</p>
			<p>[𝟱𝟬𝟭(𝗖)(𝟯) 𝗡𝗼𝗻-𝗣𝗿𝗼𝗳𝗶𝘁 𝗢𝗿𝗴𝗮𝗻𝗶𝘇𝗮𝘁𝗶𝗼𝗻 𝗦𝗲𝗿𝘃𝗶𝗻𝗴 𝗦𝗮𝗻𝗴𝗮𝘁 𝗣𝗮𝗿𝗶𝘄𝗮𝗿]</p>
		</div>
	</div>
</div>