import { Component, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/routes/app-routes';

@Component({
	selector: 'app-privacy-policy-component',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

	ngOnInit(): void {
	}

	public get appRoutes(): typeof AppRoutes {
		return AppRoutes;
	}
}