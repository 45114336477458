import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { RealmEndpointProviderService } from '../services/realm-endpoint-provider.service';
import { RealmEndpointModel } from 'src/app/shared/models/RealmEndpointModel';

@Injectable({
	providedIn: 'root'
})
export class RestApiService {

	constructor(private httpClient: HttpClient,
		private endpointService: RealmEndpointProviderService) {
	}

	public getUri(path: string): string {
		let model: RealmEndpointModel = this.endpointService.get();
		return model.restApiUri.append(path).trailingSlashNo;
	}

	public delete(path: string): Observable<boolean> {
		throw new Error('Method not implemented.');
	}

	public get<T>(path: string): Observable<T> {
		let uri: string = this.getUri(path);
		return this.httpClient.get<T>(uri);
	}

	public patch<T>(path: string): Observable<T> {
		let uri: string = this.getUri(path);
		let body: null = null;
		//return this.httpClient.patch(uri, body, { observe: 'response' }).pipe(
		//switchMap(res => (res.status >= 200 && res.status < 300) ? of(true) : of(false))
		//);
		return this.httpClient.patch<T>(uri, body);
	}

	public post<T>(path: string, obj: T): Observable<T> {
		let uri: string = this.getUri(path);
		return this.httpClient.post<T>(uri, obj);
	}

	public put<T>(path: string, obj: T): Observable<T> {
		let uri: string = this.getUri(path);
		return this.httpClient.put<T>(uri, obj);
	}
}