import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-book-insert-page',
	templateUrl: './book-insert.page.html',
	styleUrls: ['./book-insert.page.scss']
})
export class BookInsertPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}