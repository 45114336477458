import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { SharedFormService } from 'src/app/core/services/shared-form.service';
import { SatsangModel } from 'src/app/shared/models/api/SatsangModel';
import { UserRoleKind } from 'src/app/shared/models/lookup/UserRoleKind';

@Component({
	selector: 'app-satsangs',
	templateUrl: './satsangs.component.html',
	styleUrls: ['./satsangs.component.scss']
})
export class SatsangsComponent implements OnInit {
	public mobileScreen: boolean = false;
	public addSatsangModel: boolean = false;
	public satsangFormGroup: FormGroup;
	public satsangList: SatsangModel[] = [];
	public showSatsang: boolean = false;
	public satsangDetails: any = '';
	public userProfile: any = '';
	public draft: SatsangModel[] = [];
	public loggedIn: boolean = false;
	public displaySuccess: boolean = false;
	public message: string = '';

	constructor(
		public fb: FormBuilder,
		private dynamicDataProviderService: DynamicDataProviderService,
		public sharedFormService: SharedFormService,
		public authTokenStoreService: AuthTokenStoreService
	) {
		this.satsangFormGroup = this.fb.group({});
	}

	ngOnInit(): void {
		let screenWidth = window.screen.width;
		if (screenWidth <= 800) {
			this.mobileScreen = true;
		}

		this.satsangFormGroup = this.fb.group({
			title: this.fb.control(''),
			detail: this.fb.control(''),
			date: this.fb.control(new Date())
		})

		this.dynamicDataProviderService.getSatsangs('20200101', this.sharedFormService.convertMomentToDateFormat(new Date(), 'yyyyMMDD')).subscribe((resp) => {
			this.satsangList = resp;
		});
		this.isLoggedIn();
		this.getProfile();
	}

	isLoggedIn() {
		this.loggedIn = this.authTokenStoreService.isLoggedIn();
	}

	cancelSatsang() {
		this.addSatsangModel = false;
	}

	addNewESatsang() {
		this.addSatsangModel = true;
	}

	submitSatsang() {
		this.dynamicDataProviderService.postSatsang(this.satsangFormGroup.value).subscribe((resp) => {
			this.addSatsangModel = false;
			this.message = "Satsang publish successfully"
			this.displaySuccess = true;
		});
	}

	showMore(data: string | undefined) {
		if (data) {
			this.showSatsang = true;
			this.satsangDetails = data;
		}
	}

	getProfile() {
		this.dynamicDataProviderService.getProfile().subscribe(resp => {
			this.userProfile = resp.roles || [];
			if (this.userProfile.includes(UserRoleKind.PUBLISHER)) {
				this.dynamicDataProviderService.getDraftSatsangs('20200101', this.sharedFormService.convertMomentToDateFormat(new Date(), 'yyyyMMDD')).subscribe(resp => {
					this.draft = resp;
				})
			}
		})
	}

	onPublishSatsang(event: any) {
		this.dynamicDataProviderService.patchPublishSatsang(event.id).subscribe(resp => {
			this.displaySuccess = true;
			this.message = "Satsang publish successfully"
			this.dynamicDataProviderService.getDraftSatsangs('20200101', this.sharedFormService.convertMomentToDateFormat(new Date(), 'yyyyMMDD')).subscribe(resp => {
				this.draft = resp;
			})
			this.dynamicDataProviderService.getSatsangs('20200101', this.sharedFormService.convertMomentToDateFormat(new Date(), 'yyyyMMDD')).subscribe((resp) => {
				this.satsangList = resp;
			});
		})
	}
}