import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-book-view-page',
	templateUrl: './book-view.page.html',
	styleUrls: ['./book-view.page.scss']
})
export class BookViewPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}