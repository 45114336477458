import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppRoutes } from './app-routes';

import { HomePage } from '../modules/pages/home/home.page';
import { DeveloperPage } from '../modules/pages/developer/developer.page';
import { DonationPage } from '../modules/pages/donation/donation.page';
import { PrivacyPolicyPage } from '../modules/pages/privacy-policy/privacy-policy.page';

import { AuthCallbackPage } from '../modules/pages/auth-callback/auth-callback.page';
import { AuthSignInPage } from '../modules/pages/auth-sign-in/auth-sign-in.page';
import { AuthSignOutPage } from '../modules/pages/auth-sign-out/auth-sign-out.page';

import { BookViewPage } from '../modules/pages/book-view/book-view.page';
import { BulletinViewPage } from '../modules/pages/bulletin-view/bulletin-view.page';
import { EventViewPage } from '../modules/pages/event-view/event-view.page';
import { GuidelineViewPage } from '../modules/pages/guideline-view/guideline-view.page';
import { PlaylistViewPage } from '../modules/pages/playlist-view/playlist-view.page';

import { BookInsertPage } from '../modules/pages/book-insert/book-insert.page';
import { BulletinInsertPage } from '../modules/pages/bulletin-insert/bulletin-insert.page';
import { EventInsertPage } from '../modules/pages/event-insert/event-insert.page';
import { GuidelineInsertPage } from '../modules/pages/guideline-insert/guideline-insert.page';
import { PlaylistInsertPage } from '../modules/pages/playlist-insert/playlist-insert.page';

import { BookUpdatePage } from '../modules/pages/book-update/book-update.page';
import { BulletinUpdatePage } from '../modules/pages/bulletin-update/bulletin-update.page';
import { EventUpdatePage } from '../modules/pages/event-update/event-update.page';
import { GuidelineUpdatePage } from '../modules/pages/guideline-update/guideline-update.page';
import { PlaylistUpdatePage } from '../modules/pages/playlist-update/playlist-update.page';
import { SatsangsComponent } from '../modules/components/satsangs/satsangs.component';

const routes: Routes = [
	{
		path: AppRoutes.HOME,
		component: HomePage
	},
	{
		path: AppRoutes.AUTH_CALLBACK,
		component: AuthCallbackPage
	},
	{
		path: AppRoutes.AUTH_SIGN_IN,
		component: AuthSignInPage
	},
	{
		path: AppRoutes.AUTH_SIGN_OUT,
		component: AuthSignOutPage
	},
	{
		path: AppRoutes.DEVELOPER,
		component: DeveloperPage
	},
	{
		path: AppRoutes.DONATION,
		component: DonationPage
	},
	{
		path: AppRoutes.PRIVACY_POLICY,
		component: PrivacyPolicyPage
	},
	{
		path: AppRoutes.BOOK_VIEW,
		component: BookViewPage
	},
	{
		path: AppRoutes.BULLETIN_VIEW,
		component: BulletinViewPage
	},
	{
		path: AppRoutes.EVENT_VIEW,
		component: EventViewPage
	},
	{
		path: AppRoutes.GUIDELINE_VIEW,
		component: GuidelineViewPage
	},
	{
		path: AppRoutes.PLAYLIST_VIEW,
		component: PlaylistViewPage
	},
	{
		path: AppRoutes.SATSANG_VIEW,
		component: SatsangsComponent
	},
	{
		path: AppRoutes.BOOK_INSERT,
		component: BookInsertPage
		//canActivate: [LoginGuard]
	},
	{
		path: AppRoutes.DEFAULT,
		component: HomePage
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule {
}