import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-playlist-view-page',
	templateUrl: './playlist-view.page.html',
	styleUrls: ['./playlist-view.page.scss']
})
export class PlaylistViewPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}