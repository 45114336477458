import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class SharedFormService {

	// date validations
	// public formatDate(value: string | any[] = []): string {
	//	 let date = moment(value);
	//	 if (date.isValid) {
	//		 return date.format('MMDDYYYY');
	//	 }
	// }

	public formatISODate(value: string | any[] = []): string {
		const date = moment(value);

		//console.log('date for format', date);

		if (date.isValid()) {

			return this.convertMomentToDateStr(date);
		}

		return '';
	}

	public convertMomentToDateStr(date: any) {
		const d = new Date(date);
		return moment(d).format('YYYY-MM-DD');
	}

	public convertMomentToDateFormat(date: any, format: string) {
		const d = new Date(date);
		return moment(d).format(format);
	}

	public getMonthName(date: any) {
		const d = new Date(date);
		const month = d.getMonth();
		return moment(month + 1, 'MM').format('MMM');
	}

	public getDay(date: any) {
		const d = new Date(this.convertUTCtoLocalTime(date).replace(' ', 'T'));
		return d.getDate();
	}

	public getDayWeek(date: any) {
		const d = new Date(this.convertUTCtoLocalTime(date).replace(' ', 'T'));
		const check = moment(d);
		return check.format('dddd');
	}

	public getYear(date: any) {
		const d = new Date(this.convertUTCtoLocalTime(date).replace(' ', 'T'));
		return d.getFullYear().toString().substr(-2);
	}

	public getTime(date: any) {
		const d = new Date(this.convertUTCtoLocalTime(date).replace(' ', 'T'));
		const stillUtc = moment.utc(d).toDate();
		return moment(stillUtc).local().format('HH:mm');
	}

	public formatDate(date: any) {
		const d = new Date(this.convertUTCtoLocalTime(date).replace(' ', 'T'));
		return moment(d).format('LL');
	}

	public timeEnableEvent(dt1: any, dt2: any) {
		let diff = (dt1.getTime() - dt2.getTime()) / 1000;
		if (diff < 0) {
			return -1;
		} else {
			diff /= 60;
			return Math.abs(Math.round(diff));
		}
	}

	public enableJoinBtn(startTime: any, endDate: any) {
		const d = new Date(this.convertUTCtoLocalTime(startTime));
		const d2 = new Date(this.convertUTCtoLocalTime(endDate));
		let diff = this.timeEnableEvent(d, new Date());
		let diff2 = this.timeEnableEvent(d2, new Date());
		if (diff < 20 && diff2 >= 0) {
			return true;
		}

		return false;
	}

	public validateDate() {
		return (formControl: AbstractControl) => {
			let val = formControl.value;
			let date = moment(val);

			if (!date.isValid()) {
				return { validateBirthdate: 'Please enter a valid date' };
			} else {
				return null;
			}
		}
	}

	public isBetween(dateToCheck: string, minDate: string, maxDate: string): boolean {
		let a = moment(dateToCheck);
		let b = moment(minDate);
		let c = moment(maxDate);

		if (a.isValid() && b.isValid() && c.isValid()) {

			return a.isBetween(b, c, 'minutes', '[]');
		}

		return false;
	}

	public convertUTCtoLocalTime(date: any): string {
		let stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
	}

	convertLocalToUtc(date: any, startTime: any, isAM: string): string {
		// let startDate = this.convertMomentToDateFormat(date, 'YYYY-MM-DD');
		const hTime = startTime.split('.');
		const time = (isAM === 'AM' ? hTime[0] : parseInt(hTime[0]) + 12);

		let dt = date + ' ' + time + ':' + (hTime[1] === '5' ? '30' : '00') + ':00';
		let now = new Date(dt)

		return now.toISOString();
	}
}
