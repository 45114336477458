import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthTokenModel } from 'src/app/shared/models/api/AuthTokenModel';

@Injectable({
	providedIn: 'root'
})
export class AuthTokenStoreService {

	private static readonly COOKIE_NAME: string = "guruji_c";
	public loggedIn: Subject<boolean> = new Subject();

	constructor(public router: Router) {
	}

	public get(): string | null {
		const cookiePrefix: string = `${AuthTokenStoreService.COOKIE_NAME}=`;
		let cookies: Array<string> = document.cookie.split(';');

		for (let index in cookies) {
			let cookieValue: string = cookies[index].replace(/^\s+/g, '');

			if (cookieValue.startsWith(cookiePrefix)) {
				return cookieValue.substring(cookiePrefix.length, cookieValue.length);
			}
		}

		return null;
	}

	public isLoggedIn(): boolean {
		let model: string | null = this.get();
		let result: boolean = false;

		if (model?.length) {
			result = model.length > 0;
		}

		console.log('isLoggedIn => ' + result);
		return result;
	}

	public set(model: AuthTokenModel): void {
		this.setCookie(model);
		this.router.navigate(['/']);
	}

	public unset(): void {
		this.setCookie(null);
		this.router.navigate(['/']);
	}

	private setCookie(model: AuthTokenModel | null): void {
		let time: number = model?.expiresInSeconds ? model.expiresInSeconds * 1000 : -1;

		// google :: cookie max-age vs expires
		let dt: Date = new Date();
		dt.setTime(dt.getTime() + time);
		let expires: string = dt.toUTCString();

		let c: string = `${AuthTokenStoreService.COOKIE_NAME}=${model?.value}; path=/; expires=${expires};`; // HttpOnly; SameSite=Strict;Secure;`;
		console.log('setCookie => ' + c);
		document.cookie = c;
	}
}