import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { PlaylistModel } from 'src/app/shared/models/PlaylistModel';

@Component({
	selector: 'app-playlist-update',
	templateUrl: './playlist-update.component.html',
	styleUrls: ['./playlist-update.component.scss']
})
export class PlaylistUpdateComponent implements OnInit {

	public models: PlaylistModel[] = [];

	constructor(private dynamicDataProviderService: DynamicDataProviderService, public translate: TranslateService) {
	}

	ngOnInit(): void {
		this.dynamicDataProviderService.getPlaylists().subscribe({
			next: x => this.models = x,
		});
	}
}