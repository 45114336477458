import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'src/app/shared/models/lookup/LanguageModel';

@Injectable({
	providedIn: 'root'
})
export class LanguageProviderService {

	constructor() {
	}

	public defaultLanguage(): LanguageModel {
		const defaultCode: string = 'en';
		let arr: LanguageModel[] = this.all().filter(x => x.code === defaultCode);
		return arr.length === 1 ? arr[0] : { code: defaultCode };
	}

	public all(): LanguageModel[] {
		return [
			{ code: 'en' },
			{ code: 'hi' },
			{ code: 'pa' },
		];
	}

	public setup(translate: TranslateService) {
		let defaultCode = this.defaultLanguage().code;
		let langs: string[] = this.all().map(lang => lang.code);
		let matcher: string = langs.join('|');

		translate.addLangs(langs);
		translate.setDefaultLang(defaultCode);

		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match('/' + matcher + '/') ? browserLang : defaultCode);
	}
}