import { Injectable } from '@angular/core';

import csc, { ICountry, IState } from 'country-state-city';

import { CountryModel } from 'src/app/shared/models/lookup/CountryModel';
import { NameValueModel } from 'src/app/shared/models/lookup/NameValueModel';

@Injectable({
	providedIn: 'root'
})
export class LookupDataProvider {

	public countries(): CountryModel[] {
		let preferedCountries: string[] = [ 'AE', 'CA', 'IN', 'US', ];

		let countries: ICountry[] = csc.getAllCountries();
		let result: CountryModel[] = [];

		for (let prefered of [ true, false ]) {
			for (let country of countries) {
				if (preferedCountries.includes(country.isoCode) === prefered) {
					let item: CountryModel = {
						'code': country.isoCode,
						'name': country.name,
						'dialPrefix': country.phonecode,
						'states': [],
					};

					let states: IState[] = csc.getStatesOfCountry(country.isoCode);
					for (let state of states) {
						item.states.push({
							'code': state.isoCode,
							'name': state.name,
						});
					}

					result.push(item);
				}
			}
		}

		return result;
	}

	public months(): NameValueModel[] {
		let value: number = -1;

		return [
			{ 'name': 'Jan', 'value': ++value },
			{ 'name': 'Feb', 'value': ++value },
			{ 'name': 'Mar', 'value': ++value },
			{ 'name': 'Apr', 'value': ++value },
			{ 'name': 'May', 'value': ++value },
			{ 'name': 'Jun', 'value': ++value },
			{ 'name': 'Jul', 'value': ++value },
			{ 'name': 'Aug', 'value': ++value },
			{ 'name': 'Sep', 'value': ++value },
			{ 'name': 'Oct', 'value': ++value },
			{ 'name': 'Nov', 'value': ++value },
			{ 'name': 'Dec', 'value': ++value },
		];
	}

	public weekdays(): NameValueModel[] {
		let value: number = -1;

		return [
			{ name: 'Sunday', value: ++value },
			{ name: 'Monday', value: ++value },
			{ name: 'Tuesday', value: ++value },
			{ name: 'Wednesday', value: ++value },
			{ name: 'Thursday', value: ++value },
			{ name: 'Friday', value: ++value },
			{ name: 'Saturday', value: ++value },
		];
	}
}