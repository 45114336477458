import { Component, OnInit } from '@angular/core';
import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';

@Component({
	selector: 'app-auth-sign-out',
	templateUrl: './auth-sign-out.component.html',
	styleUrls: ['./auth-sign-out.component.scss']
})
export class AuthSignOutComponent implements OnInit {

	constructor(private authTokenStoreService: AuthTokenStoreService) {
	}

	ngOnInit(): void {
		this.authTokenStoreService.unset();
	}
}