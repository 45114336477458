import { SafePipe } from './core/pipes/safe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrimeNgComponentsModule } from './shared/prime-ng/prime-ng-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

import { HeroViewComponent } from './shared/components/hero-view/hero-view.component';
import { HomeComponent } from './modules/components/home/home.component';
import { DeveloperComponent } from './modules/components/developer/developer.component';
import { DonationComponent } from './modules/components/donation/donation.component';
import { PrivacyPolicyComponent } from './modules/components/privacy-policy/privacy-policy.component';

import { BookViewComponent } from './modules/components/book-view/book-view.component';
import { BulletinViewComponent } from './modules/components/bulletin-view/bulletin-view.component';
import { EventViewComponent } from './modules/components/event-view/event-view.component';
import { GuidelineViewComponent } from './modules/components/guideline-view/guideline-view.component';
import { PlaylistViewComponent } from './modules/components/playlist-view/playlist-view.component';

import { BookInsertComponent } from './modules/components/book-insert/book-insert.component';
import { BulletinInsertComponent } from './modules/components/bulletin-insert/bulletin-insert.component';
import { EventInsertComponent } from './modules/components/event-insert/event-insert.component';
import { GuidelineInsertComponent } from './modules/components/guideline-insert/guideline-insert.component';
import { PlaylistInsertComponent } from './modules/components/playlist-insert/playlist-insert.component';

import { BookUpdateComponent } from './modules/components/book-update/book-update.component';
import { BulletinUpdateComponent } from './modules/components/bulletin-update/bulletin-update.component';
import { EventUpdateComponent } from './modules/components/event-update/event-update.component';
import { GuidelineUpdateComponent } from './modules/components/guideline-update/guideline-update.component';
import { PlaylistUpdateComponent } from './modules/components/playlist-update/playlist-update.component';

import { HomePage } from './modules/pages/home/home.page';
import { DeveloperPage } from './modules/pages/developer/developer.page';
import { DonationPage } from './modules/pages/donation/donation.page';
import { PrivacyPolicyPage } from './modules/pages/privacy-policy/privacy-policy.page';

import { AuthCallbackPage } from './modules/pages/auth-callback/auth-callback.page';
import { AuthSignInPage } from './modules/pages/auth-sign-in/auth-sign-in.page';
import { AuthSignOutPage } from './modules/pages/auth-sign-out/auth-sign-out.page';

import { BookViewPage } from './modules/pages/book-view/book-view.page';
import { BulletinViewPage } from './modules/pages/bulletin-view/bulletin-view.page';
import { EventViewPage } from './modules/pages/event-view/event-view.page';
import { GuidelineViewPage } from './modules/pages/guideline-view/guideline-view.page';
import { PlaylistViewPage } from './modules/pages/playlist-view/playlist-view.page';

import { BookInsertPage } from './modules/pages/book-insert/book-insert.page';
import { BulletinInsertPage } from './modules/pages/bulletin-insert/bulletin-insert.page';
import { EventInsertPage } from './modules/pages/event-insert/event-insert.page';
import { GuidelineInsertPage } from './modules/pages/guideline-insert/guideline-insert.page';
import { PlaylistInsertPage } from './modules/pages/playlist-insert/playlist-insert.page';

import { BookUpdatePage } from './modules/pages/book-update/book-update.page';
import { BulletinUpdatePage } from './modules/pages/bulletin-update/bulletin-update.page';
import { EventUpdatePage } from './modules/pages/event-update/event-update.page';
import { GuidelineUpdatePage } from './modules/pages/guideline-update/guideline-update.page';
import { PlaylistUpdatePage } from './modules/pages/playlist-update/playlist-update.page';
import { SatsangsComponent } from './modules/components/satsangs/satsangs.component';

import { AuthCallbackComponent } from './modules/components/auth-callback/auth-callback.component';
import { AuthSignInComponent } from './modules/components/auth-sign-in/auth-sign-in.component';
import { AuthSignOutComponent } from './modules/components/auth-sign-out/auth-sign-out.component';
import { AuthInterceptor } from './core/http/auth.interceptor';

@NgModule({
	declarations: [
		AppComponent
		, SafePipe

		, FooterComponent
		, HeaderComponent

		, HeroViewComponent
		, HomeComponent
		, AuthCallbackComponent
		, AuthSignInComponent
		, AuthSignOutComponent

		, HomePage
		, AuthCallbackPage
		, AuthSignInPage
		, AuthSignOutPage

		, BookViewComponent
		, BulletinViewComponent
		, DeveloperComponent
		, DonationComponent
		, PrivacyPolicyComponent
		, EventViewComponent
		, GuidelineViewComponent
		, PlaylistViewComponent

		, BookInsertComponent
		, BulletinInsertComponent
		, EventInsertComponent
		, GuidelineInsertComponent
		, PlaylistInsertComponent

		, BookUpdateComponent
		, BulletinUpdateComponent
		, EventUpdateComponent
		, GuidelineUpdateComponent
		, PlaylistUpdateComponent

		, BookViewPage
		, BulletinViewPage
		, DeveloperPage
		, DonationPage
		, PrivacyPolicyPage
		, EventViewPage
		, GuidelineViewPage
		, PlaylistViewPage

		, BookInsertPage
		, BulletinInsertPage
		, EventInsertPage
		, GuidelineInsertPage
		, PlaylistInsertPage

		, BookUpdatePage
		, BulletinUpdatePage
		, EventUpdatePage
		, GuidelineUpdatePage
		, PlaylistUpdatePage
		, SatsangsComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgbModule,
		PrimeNgComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
