<nav class="navbar navbar-expand-sm guruji-navbar">
	<div class="bodyContainer">
		<div class="row">
			<div class="col-3">
					<a class="navbar-brand site guruji-header-link" routerLink="{{ this.appRoutes.DEFAULT }}">{{ 'header.brand' | translate }}</a>
			</div>
			<div class="col-9">
				<ul class="nav navbar-nav ml-auto d-flex justify-content-end">

					<li class="nav-item">
						<ul class="nav navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.HOME }}">{{ 'header.home' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.EVENT_VIEW }}">{{ 'header.satsang' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.BULLETIN_VIEW }}">{{ 'header.bulletins' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.SATSANG_VIEW }}">{{ 'header.shareYourJourney' | translate }}</a>
							</li>
							<!--
							<li class="nav-item">
								<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.PLAYLIST_VIEW }}">{{ 'header.playlists' | translate }}</a>
							</li>
							-->
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.BOOK_VIEW }}">{{ 'header.books' | translate }}</a>
							</li>
							<li class="nav-item" *ngIf="!loggedIn">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.AUTH_SIGN_IN }}">{{ 'header.login' | translate }}</a>
							</li>
							<li class="nav-item" *ngIf="loggedIn">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.AUTH_SIGN_OUT }}">{{ 'header.logout' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" [routerLinkActive]="['active']" routerLink="{{ this.appRoutes.DONATION }}">{{ 'header.donations' | translate }}</a>
							</li>
							<!--
							<li class="nav-item">
								<a class="nav-link guruji-header-link" (click)="toggleLanguage('en')">{{ 'lookups.language.en' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" (click)="toggleLanguage('hi')">{{ 'lookups.language.hi' | translate }}</a>
							</li>
							<li class="nav-item">
								<a class="nav-link guruji-header-link" (click)="toggleLanguage('pa')">{{ 'lookups.language.pa' | translate }}</a>
							</li>
							-->
						</ul>
					</li>

				</ul>
			</div>

		</div>
		<div class="row divider"></div>
	</div>

</nav>

<nav class="navbar navbar-expand-sm fixed-top guruji-navbar-mobile">
	<a href="javascript:void(0);" class="icon" (click)="onIconClick()">
		<i class="fa fa-bars">
			<ul *ngIf="expandMenu" class="mobileNav">
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.HOME }}">{{ 'header.home' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.EVENT_VIEW }}">{{ 'header.satsang' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.BULLETIN_VIEW }}">{{ 'header.bulletins' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.SATSANG_VIEW }}">{{ 'header.shareYourJourney' | translate }}</a>
				</li>
				<!-- <li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.PLAYLIST_VIEW }}">{{ 'header.playlists' | translate }}</a>
				</li> -->
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.BOOK_VIEW }}">{{ 'header.books' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.AUTH_SIGN_IN }}">{{ 'header.login' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" routerLink="{{ this.appRoutes.DONATION }}">{{ 'header.donations' | translate }}</a>
				</li>
				<!-- <li class="nav-item">
					<a class="nav-link guruji-header-link" (click)="toggleLanguage('en')">{{ 'lookups.language.en' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" (click)="toggleLanguage('hi')">{{ 'lookups.language.hi' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link guruji-header-link" (click)="toggleLanguage('pa')">{{ 'lookups.language.pa' | translate }}</a>
				</li> -->
			</ul>
		</i>
	</a>
</nav>