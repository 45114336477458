import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-book-update-page',
	templateUrl: './book-update.page.html',
	styleUrls: ['./book-update.page.scss']
})
export class BookUpdatePage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}