import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-event-update-page',
	templateUrl: './event-update.page.html',
	styleUrls: ['./event-update.page.scss']
})
export class EventUpdatePage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}