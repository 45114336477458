<div class="container">
	<div class="row">
		<div class="col-12">
			<h4 id="tdesc" class="eventTitle">{{ 'content.playlist.view' | translate }}</h4>
		</div>
	</div>
	<div class="row body-margin-left">
		<div class="col-12">
			<div class="sidebar_wrap">
				<div class="sidebar_widgets p-0">
					<table class="table table-striped" aria-describedby="tdesc">
						<thead>
							<tr>
								<th scope="col">{{ 'content.playlist.id' | translate }}</th>
								<th scope="col">{{ 'content.playlist.title' | translate }}</th>
								<!-- <th scope="col">{{ 'content.playlist.audioUri' | translate }}</th>
								<th scope="col">{{ 'content.playlist.videoUri' | translate }}</th> -->
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let model of models">
								<td>{{ model?.id }}</td>
								<td>{{ model?.title }}</td>
								<!-- <td>{{ model?.audioUri }}</td>
								<td>{{ model?.videoUri }}</td> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>