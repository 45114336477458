import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { GuidelineModel } from 'src/app/shared/models/GuidelineModel';

@Component({
	selector: 'app-guideline-update',
	templateUrl: './guideline-update.component.html',
	styleUrls: ['./guideline-update.component.scss']
})
export class GuidelineUpdateComponent implements OnInit {

	public models: GuidelineModel[] = [];

	constructor(private dynamicDataProviderService: DynamicDataProviderService, public translate: TranslateService) {
	}

	ngOnInit(): void {
		this.dynamicDataProviderService.getGuidelines().subscribe({
			next: x => this.models = x,
		});
	}
}