import { Component, OnInit } from '@angular/core';

import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';
import { RealmConfigProviderService } from 'src/app/core/services/realm-config-provider.service';
import { RealmEndpointProviderService } from 'src/app/core/services/realm-endpoint-provider.service';
import { UriModel } from 'src/app/shared/models/UriModel';

@Component({
	selector: 'app-auth-sign-in',
	templateUrl: './auth-sign-in.component.html',
	styleUrls: ['./auth-sign-in.component.scss']
})
export class AuthSignInComponent implements OnInit {

	constructor(private authTokenStoreService: AuthTokenStoreService,
		private realmConfigProviderService: RealmConfigProviderService,
		private realmEndpointProviderService: RealmEndpointProviderService) {
	}

	ngOnInit(): void {
		let t: string | null = this.authTokenStoreService.get();

		if (t === null || t.length === 0) {
			this.realmConfigProviderService.get().subscribe(config => {
				let cognitoDomainUri: UriModel = new UriModel(config.cognitoDomainUri);
				let cognitoRedirectUri: UriModel = this.realmEndpointProviderService.get().cognitoRedirectUri;

				window.location.href = cognitoDomainUri.trailingSlashNo + '/login'
											+ '?' + 'client_id' + '=' + config.cognitoAppClientId
											+ '&' + 'scope' + '=' + config.cognitoScopes
											+ '&' + 'redirect_uri' + '=' + cognitoRedirectUri.trailingSlashYes
											+ '&' + 'response_type' + '=' + 'code';
			});
		}
		else {
			window.location.href = '/';
		}
	}
}