import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//import { DomSanitizer } from '@angular/platform-browser';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { BookModel } from 'src/app/shared/models/api/BookModel';

@Component({
	selector: 'app-book-view',
	templateUrl: './book-view.component.html',
	styleUrls: ['./book-view.component.scss']
})
export class BookViewComponent implements OnInit {

	public models: BookModel[] = [];
	public mobileScreen: boolean = false;
	constructor(
		private dynamicDataProviderService: DynamicDataProviderService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.dynamicDataProviderService.getBooks().subscribe(resp => {

			// for (let list of resp) {
			// 	this.dynamicDataProviderService.getBookThumbnail(list.id).subscribe(resp => {
			// 		list['thumbnailImg'] = resp;
			// 	})
			// }

			this.models = resp;
		});

		let screenWidth = window.screen.width;
		if (screenWidth <= 800) {
			this.mobileScreen = true;
		}
	}

	getBookDownloadUri(id: string | undefined): string {
		return this.dynamicDataProviderService.getBookDownloadUri(id!);
	}

	getBookThumbnailUri(id: string | undefined): string {
		return this.dynamicDataProviderService.getBookThumbnailUri(id!);
	}

	getLanguages(languageCodes: string[] | undefined): string {
		// TODO - better
		let s: string = '';
		if (languageCodes) {
			for (let index in languageCodes) {
				if (s.length > 0) {
					s += ', ';
				}
				if (languageCodes[index] === 'en') {
					s += 'English';
				}
				if (languageCodes[index] === 'hi') {
					s += 'Hindi';
				}
				if (languageCodes[index] === 'pa') {
					s += 'Punjabi';
				}
			}
		}
		return s;
	}
}