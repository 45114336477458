<div class="bodyContainer">
	<div class="row">
		<div class="col-12 divider"></div>
	</div>
	<div class="row headerTopMargin">
		<div class="col-12 headerTopMargin row">
			<div class="book-list col-4" *ngFor="let model of models; let i=index">
				<div class="event_info">
					<h6>Name: {{ model?.name }}</h6>
					<h6>Contact: {{ model?.contact }}</h6>
					<h6>Languages: {{ model?.roles }}</h6>
				</div>
			</div>
		</div>

		<h6>Today's date: {{ today }}</h6>
	</div>
</div>