import { RealmEndpointModel } from 'src/app/shared/models/RealmEndpointModel';

export abstract class RealmEndpointProviderServiceBase {

 	public static readonly RootDomain: string = 'guruji' + 'ki' + 'e' + 'sangat';

	private static model: RealmEndpointModel | null = null;

	public get(): RealmEndpointModel {
		if (RealmEndpointProviderServiceBase.model === null) {
			RealmEndpointProviderServiceBase.model = this.getInternal();
			console.log('restApiUri => ' + RealmEndpointProviderServiceBase.model.restApiUri.trailingSlashYes);
			console.log('cognitoRedirectUri => ' + RealmEndpointProviderServiceBase.model.cognitoRedirectUri.trailingSlashYes);
		}

		return RealmEndpointProviderServiceBase.model;
	}

	protected abstract getInternal(): RealmEndpointModel;
}