import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bulletin-insert-page',
	templateUrl: './bulletin-insert.page.html',
	styleUrls: ['./bulletin-insert.page.scss']
})
export class BulletinInsertPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}