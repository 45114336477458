import { SharedFormService } from './../../../core/services/shared-form.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { LookupDataProvider } from 'src/app/core/services/lookup-data-provider.service';

import { CountryModel } from 'src/app/shared/models/lookup/CountryModel';
import { NameValueModel } from 'src/app/shared/models/lookup/NameValueModel';
import { StateModel } from 'src/app/shared/models/lookup/StateModel';
import { EventModel } from 'src/app/shared/models/api/EventModel';
import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';
import { UserRoleKind } from 'src/app/shared/models/lookup/UserRoleKind';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-event-view',
	templateUrl: './event-view.component.html',
	styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {

	public models: EventModel[] = [];
	public addEventModal: boolean = false;
	public eventFormGroup: FormGroup;
	public monthFormGroup: FormGroup;
	public submittedReg: boolean = false;
	public timeEnabled: boolean = false;
	public errorMessage: string = '';
	public todayDate: any = new Date();

	public countryModels: CountryModel[] = [];
	public stateModels: StateModel[] = [];
	public monthModels: NameValueModel[] = [];
	public weekdayModels: NameValueModel[] = [];

	public displaySuccess: boolean = false;

	public timeList: any[] = [
		{ label: 'AM', value: 'AM' },
		{ label: 'PM', value: 'PM' },
	];

	public monthClick: boolean = false;
	public currentMonth: string = 'Mar';
	public currentYear: number = 2020;
	public week1: any[] = [];
	public week2: any[] = [];
	public week3: any[] = [];
	public week4: any[] = [];
	public week5: any[] = [];
	public addMonthModal: boolean = false;
	public eventDetailsModal: boolean = false;
	public selectSatsangDetails: any;
	public displayWeek1: boolean = false;
	public displayWeek2: boolean = false;
	public displayWeek3: boolean = false;
	public displayWeek4: boolean = false;
	public displayWeek5: boolean = false;
	public mobileScreen: boolean = false;

	public invalidData: boolean = false;

	public years: any[] = [];
	public nextMonth: any;
	public prevMonth: any;
	public nextMonthNo: any = 0;
	public prevMonthNo: any = 0;
	public currentMonthNo: any = 0;
	public currentWeek: any;
	public guidelinesPopup: boolean = false;
	public draft: any[] = [];
	public message: string = '';

	public timing: any[] = [
		{ label: '01:00', value: 1.00 },
		{ label: '01:30', value: 1.50 },
		{ label: '02:00', value: 2.00 },
		{ label: '02:30', value: 2.50 },
		{ label: '03:00', value: 3.00 },
		{ label: '03:30', value: 3.50 },
		{ label: '04:00', value: 4.00 },
		{ label: '04:30', value: 4.50 },
		{ label: '05:00', value: 5.00 },
		{ label: '05:30', value: 5.50 },
		{ label: '06:00', value: 6.00 },
		{ label: '06:30', value: 6.50 },
		{ label: '07:00', value: 7.00 },
		{ label: '07:30', value: 7.50 },
		{ label: '08:00', value: 8.00 },
		{ label: '08:30', value: 8.50 },
		{ label: '09:00', value: 9.00 },
		{ label: '09:30', value: 9.50 },
		{ label: '10:00', value: 10.00 },
		{ label: '10:30', value: 10.50 },
		{ label: '11:00', value: 11.00 },
		{ label: '11:30', value: 11.50 },
		{ label: '12:00', value: 12.00 },
		{ label: '12:30', value: 12.50 },
	]

	public loggedIn: boolean = false;
	public userProfile: any;

	constructor(
		public sharedFormService: SharedFormService,
		public fb: FormBuilder,
		private dynamicDataProviderService: DynamicDataProviderService,
		private lookupDataProvider: LookupDataProvider,
		public translate: TranslateService,
		public authTokenStoreService: AuthTokenStoreService,
		private messageService: MessageService
	) {
		this.eventFormGroup = this.fb.group({
			eventName: this.fb.control(null, [Validators.required]),
			date: this.fb.control(null, [Validators.required]),
			startTime: this.fb.control(null, [Validators.required]),
			endTime: this.fb.control(null, [Validators.required]),
			address: this.fb.control('6 Kilmer Road'),
			address2: this.fb.control('Ste T'),
			country: this.fb.control('US'),
			state: this.fb.control('NJ'),
			city: this.fb.control('Edison'),
			zip: this.fb.control('08817'),
			eventId: this.fb.control(0),
			isActive: this.fb.control(false),
			sangatId: this.fb.control(null),
			ampmStart: this.fb.control(null, [Validators.required]),
			satsangType: this.fb.control(2, [Validators.required]),
			conferenceLink: this.fb.control('https://us02web.zoom.us/j/7324238799'),
			conferencePassword: this.fb.control(''),
			notes: this.fb.control(null)
		});

		this.monthFormGroup = this.fb.group({
			month: this.fb.control(null),
			year: this.fb.control(null)
		});

		this.years = Array(50).fill(null).map((_, i) => 2020 + i);

		this.countryModels = this.lookupDataProvider.countries();
		this.monthModels = this.lookupDataProvider.months();
		this.weekdayModels = this.lookupDataProvider.weekdays();
	}

	ngOnInit(): void {

		let date = new Date(), tmpYear = date.getFullYear(), tmpMonth = date.getMonth();
		this.onSelectMonth(tmpMonth, tmpYear);
		let monthArr = this.monthModels.filter(resp => resp.value === tmpMonth);
		this.currentMonth = monthArr[0].name;
		this.currentMonthNo = tmpMonth;
		this.nextMonth = this.monthModels[tmpMonth + 1].name;
		this.nextMonthNo = tmpMonth + 1;
		this.currentYear = tmpYear
		this.prevMonth = this.monthModels[tmpMonth - 1].name;
		this.prevMonthNo = tmpMonth - 1;

		this.monthFormGroup.patchValue({
			month: tmpMonth,
			year: tmpYear
		})

		let screenWidth = window.screen.width;
		if (screenWidth <= 800) {
			this.mobileScreen = true;
			let d = new Date(+date);
			this.currentWeek = Math.ceil(d.getDate() / 7);
			this.currentWeek = this.currentWeek + 1;
			this.showWeek(this.currentWeek);
			//console.log('week :', this.currentWeek);
		}

		this.isLoggedIn();
		this.getProfile(tmpMonth, tmpYear);
	}

	isLoggedIn() {
		this.loggedIn = this.authTokenStoreService.isLoggedIn();
		this.authTokenStoreService.loggedIn.next(this.loggedIn);
	}

	getProfile(month: number, year: number) {
		this.dynamicDataProviderService.getProfile().subscribe(resp => {
			this.userProfile = resp.roles || [];
			let firstDay = new Date(year, month, 1);
			let lastDay = new Date(year, month + 1, 0);
			if (this.userProfile.includes(UserRoleKind.PUBLISHER)) {
				this.dynamicDataProviderService.getDraftEvents(this.sharedFormService.convertMomentToDateFormat(firstDay, 'yyyyMMDD'), this.sharedFormService.convertMomentToDateFormat(lastDay, 'yyyyMMDD')).subscribe(resp => {
					this.draft = resp;
				})
			}
		})
	}

	onPublishEvent(event: any) {
		this.dynamicDataProviderService.patchPublishEvent(event.id).subscribe(resp => {
			console.log("published::" + resp);
			this.displaySuccess = true;
			this.message = "Event Publish Successfully";
			let date = new Date(), tmpYear = date.getFullYear(), tmpMonth = date.getMonth();
			this.onSelectMonth(tmpMonth, tmpYear);
			let firstDay = new Date(tmpYear, tmpMonth, 1);
			let lastDay = new Date(tmpYear, tmpMonth + 1, 0);
			this.messageService.add({severity:'success', summary: 'Success', detail: 'Satsang Published'});
			this.dynamicDataProviderService.getDraftEvents(this.sharedFormService.convertMomentToDateFormat(firstDay, 'yyyyMMDD'), this.sharedFormService.convertMomentToDateFormat(lastDay, 'yyyyMMDD')).subscribe(resp => {
				this.draft = resp;
			})
		})
	}

	addNewEvent() {
		this.addEventModal = true;

		this.eventFormGroup.controls.eventName.patchValue('');
		this.eventFormGroup.controls.date.patchValue('');
		this.eventFormGroup.controls.startTime.patchValue('');
		this.eventFormGroup.controls.endTime.patchValue('');
		this.eventFormGroup.controls.address.disable();
		this.eventFormGroup.controls.address2.disable();
		this.eventFormGroup.controls.country.disable();
		this.eventFormGroup.controls.city.disable();
		this.eventFormGroup.controls.state.disable();
		this.eventFormGroup.controls.zip.disable();
	}

	cancelEvent() {
		this.addEventModal = false;
	}

	submitEvent() {
		if (!this.eventFormGroup.valid) {
			this.invalidData = true;
		} else {
			let params: EventModel = {
				title: this.eventFormGroup.controls.eventName.value,
				location: {
					label: (this.eventFormGroup.controls.satsangType.value).toString(),
					addressLine1: this.eventFormGroup.controls.address.value,
					addressLine2: '',
					city: this.eventFormGroup.controls.city.value,
					stateCode: this.eventFormGroup.controls.state.value,
					postalCode: this.eventFormGroup.controls.zip.value,
					countryCode: this.eventFormGroup.controls.country.value
				},
				time: {
					begin: this.sharedFormService.convertLocalToUtc(this.eventFormGroup.controls.date.value, this.eventFormGroup.controls.startTime.value, this.eventFormGroup.controls.ampmStart.value),
					end: this.sharedFormService.convertLocalToUtc(this.eventFormGroup.controls.date.value, (parseFloat(this.eventFormGroup.controls.startTime.value) + parseFloat(this.eventFormGroup.controls.endTime.value)).toString(), this.eventFormGroup.controls.ampmStart.value)
				},
				conference: {
					uri: this.eventFormGroup.controls.conferenceLink.value,
					password: this.eventFormGroup.controls.conferencePassword.value,
				}
			};
			this.dynamicDataProviderService.postEvent(params).subscribe(response => {
				//console.log(response);
				this.onSelectMonth(this.currentMonthNo, this.currentYear);
				this.displaySuccess = true;
				this.message = "Event Submitted Successfully";
			});
			this.addEventModal = false;
		}
	}

	nextWeek() {
		this.currentWeek = this.currentWeek + 1;
		this.showWeek(this.currentWeek);
	}

	prevWeek() {
		this.currentWeek = this.currentWeek - 1;
		this.showWeek(this.currentWeek);
	}

	cancelPicker() {
		this.addMonthModal = false;
	}

	submitPicker() {
		this.addMonthModal = false;
		let m = this.monthModels.filter(resp => resp.value === parseInt(this.monthFormGroup.controls.month.value));
		let y = this.years.filter(resp => resp.value === parseInt(this.monthFormGroup.controls.year.value));
		this.currentMonth = m[0].name;
		this.currentYear = parseInt(this.monthFormGroup.controls.year.value);
		this.onSelectMonth(parseInt(this.monthFormGroup.controls.month.value), parseInt(this.monthFormGroup.controls.year.value));
	}

	onSelectMonth(month: number, year: number) {
		this.week1 = [];
		this.week2 = [];
		this.week3 = [];
		this.week4 = [];
		this.week5 = [];

		let monthArr = this.monthModels.filter(resp => resp.value === month);
		this.currentMonth = monthArr[0].name;
		this.currentMonthNo = month;
		if (month !== 11) {
			this.nextMonth = this.monthModels[month + 1].name;
			this.nextMonthNo = month + 1;
		}

		if (month !== 0) {
			this.prevMonth = this.monthModels[month - 1].name;
			this.prevMonthNo = month - 1;
		}

		this.displayWeek1 = false;
		this.displayWeek2 = false;
		this.displayWeek3 = false;
		this.displayWeek4 = false;
		this.displayWeek5 = false;

		let date = new Date(), tmpMonth = date.getMonth();
		if (tmpMonth !== month) {
			this.displayWeek1 = true;
		} else {
			let d = new Date(+date);
			this.currentWeek = Math.ceil(d.getDate() / 7);
			this.currentWeek = this.currentWeek + 1;
			this.showWeek(this.currentWeek);
		}

		let firstDay = new Date(year, month, 1);
		let lastDay = new Date(year, month + 1, 0);

		let dayWeek = this.sharedFormService.getDayWeek(firstDay);
		//console.log('firstDay: ', dayWeek);
		let start = this.weekdayModels.filter(resp => dayWeek === resp.name);

		let last = this.sharedFormService.getDay(lastDay);

		this.dynamicDataProviderService.getEvents(this.sharedFormService.convertMomentToDateFormat(firstDay, 'yyyyMMDD'), this.sharedFormService.convertMomentToDateFormat(lastDay, 'yyyyMMDD')).subscribe(resp => {
			this.models = resp;

			for (let x = 0, y = 1; x <= 35; x++) {
				if (x <= last && x <= 6) {

					if (x >= start[0].value) {
						let satsangList = this.models.filter(resp => this.sharedFormService.getDay(resp.time?.begin) === y);
						if (satsangList.length) {
							this.week1.push({ date: y, satsang: satsangList });
						} else {
							this.week1.push({ date: y, satsang: [] });
						}

						y++;
					} else {
						this.week1.push('');
					}

				} else if (x >= start[0].value && y <= last && x >= 7 && x <= 13) {
					let satsangList = this.models.filter(resp => this.sharedFormService.getDay(resp.time?.begin) === y);
					if (satsangList.length) {
						this.week2.push({ date: y, satsang: satsangList });
					} else {
						this.week2.push({ date: y, satsang: [] });
					}

					y++;
				} else if (x >= start[0].value && y <= last && x >= 14 && x <= 20) {
					let satsangList = this.models.filter(resp => this.sharedFormService.getDay(resp.time?.begin) === y);
					if (satsangList.length) {
						this.week3.push({ date: y, satsang: satsangList });
					} else {
						this.week3.push({ date: y, satsang: [] });
					}

					y++;
				} else if (x >= start[0].value && y <= last && x >= 21 && x <= 27) {
					let satsangList = this.models.filter(resp => this.sharedFormService.getDay(resp.time?.begin) === y);
					if (satsangList.length) {
						this.week4.push({ date: y, satsang: satsangList });
					} else {
						this.week4.push({ date: y, satsang: [] });
					}
					y++;
				} else if (x >= start[0].value && y <= last && x >= 28 && x <= 35) {
					if (y <= last) {
						let satsangList = this.models.filter(resp => this.sharedFormService.getDay(resp.time?.begin) === y);
						if (satsangList.length) {
							this.week5.push({ date: y, satsang: satsangList });
						} else {
							this.week5.push({ date: y, satsang: [] });
						}
						y++;
					} else {
						this.week5.push('');
					}
				}
			}
		});

	}

	onDetailSatsang(satsang: any) {
		//console.log(satsang);
		this.eventDetailsModal = true;
		this.selectSatsangDetails = satsang;
	}

	showWeek(week: number) {
		this.displayWeek1 = false;
		this.displayWeek2 = false;
		this.displayWeek3 = false;
		this.displayWeek4 = false;
		this.displayWeek5 = false;

		if (week === 1) {
			this.displayWeek1 = true;
		} else if (week === 2) {
			this.displayWeek2 = true;
		} else if (week === 3) {
			this.displayWeek3 = true;
		} else if (week === 4) {
			this.displayWeek4 = true;
		} else if (week === 5) {
			this.displayWeek5 = true;
		}
	}

	onSelectCountry(target: any): void {
		//console.log('countryCode: ', target.value);
		let country: CountryModel | undefined = this.countryModels.find(x => x.code === target.value);
		//console.log('states: ', country?.states.length);
		this.stateModels = country?.states ?? [];
	}

	onSelectLocation(event: any): void {
		if (event.target.value === '1') {
			this.eventFormGroup.patchValue({
				address: '',
				address2: '',
				city: '',
				country: '',
				state: '',
				zip: ''
			});
			this.eventFormGroup.controls.address.setValidators([Validators.required]);
			this.eventFormGroup.controls.address.updateValueAndValidity();
			this.eventFormGroup.controls.country.setValidators([Validators.required]);
			this.eventFormGroup.controls.country.updateValueAndValidity();
			this.eventFormGroup.controls.city.setValidators([Validators.required]);
			this.eventFormGroup.controls.city.updateValueAndValidity();
			this.eventFormGroup.controls.state.setValidators([Validators.required]);
			this.eventFormGroup.controls.state.updateValueAndValidity();
			this.eventFormGroup.controls.zip.setValidators([Validators.required]);
			this.eventFormGroup.controls.zip.updateValueAndValidity();
			this.eventFormGroup.controls.address.enable();
			this.eventFormGroup.controls.address2.enable();
			this.eventFormGroup.controls.country.enable();
			this.eventFormGroup.controls.city.enable();
			this.eventFormGroup.controls.state.enable();
			this.eventFormGroup.controls.zip.enable();
		} else if (event.target.value === '2') {
			this.eventFormGroup.patchValue({
				address: '6 Kilmer Road',
				address2: 'Ste T',
				country: 'US',
				state: 'NJ',
				city: 'Edison',
				zip: '08817'
			})
			this.eventFormGroup.controls.address.disable();
			this.eventFormGroup.controls.address2.disable();
			this.eventFormGroup.controls.country.disable();
			this.eventFormGroup.controls.city.disable();
			this.eventFormGroup.controls.state.disable();
			this.eventFormGroup.controls.zip.disable();
		} else {
			this.eventFormGroup.patchValue({
				address: '',
				address2: '',
				city: '',
				country: '',
				state: '',
				zip: ''
			});
			this.eventFormGroup.controls.address.clearValidators();
			this.eventFormGroup.controls.address.updateValueAndValidity();
			this.eventFormGroup.controls.country.clearValidators();
			this.eventFormGroup.controls.country.updateValueAndValidity();
			this.eventFormGroup.controls.city.clearValidators();
			this.eventFormGroup.controls.city.updateValueAndValidity();
			this.eventFormGroup.controls.state.clearValidators();
			this.eventFormGroup.controls.state.updateValueAndValidity();
			this.eventFormGroup.controls.zip.clearValidators();
			this.eventFormGroup.controls.zip.updateValueAndValidity();
			this.eventFormGroup.controls.address.disable();
			this.eventFormGroup.controls.address2.disable();
			this.eventFormGroup.controls.country.disable();
			this.eventFormGroup.controls.city.disable();
			this.eventFormGroup.controls.state.disable();
			this.eventFormGroup.controls.zip.disable();
		}
	}
}