import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestApiService } from '../http/rest-api.service';

import { ContentStateKind } from 'src/app/shared/models/lookup/ContentStateKind';

import { AuthTokenModel } from 'src/app/shared/models/api/AuthTokenModel';
import { BookModel } from 'src/app/shared/models/api/BookModel';
import { BulletinModel } from 'src/app/shared/models/api/BulletinModel';
import { DeveloperModel } from 'src/app/shared/models/api/DeveloperModel';
import { EventModel } from 'src/app/shared/models/api/EventModel';
import { GuidelineModel } from 'src/app/shared/models/GuidelineModel';
import { PatchResponseModel } from 'src/app/shared/models/api/PatchResponseModel';
import { PlaylistModel } from 'src/app/shared/models/PlaylistModel';
import { ProfileModel } from 'src/app/shared/models/api/ProfileModel';
import { RealmConfigModel } from 'src/app/shared/models/api/RealmConfigModel';
import { SatsangModel } from 'src/app/shared/models/api/SatsangModel';

@Injectable({
	providedIn: 'root'
})
export class DynamicDataProviderService {

	constructor(private restApiService: RestApiService) {
	}

	public getRealmConfig(): Observable<RealmConfigModel> {
		return this.restApiService.get<RealmConfigModel>('/realm/config');
	}

	public getAuthToken(code: string, redirectUri: string): Observable<AuthTokenModel> {
		return this.restApiService.get<AuthTokenModel>('/auth/token?code=' + code + '&redirect_uri=' + redirectUri);
	}

	public getProfile(): Observable<ProfileModel> {
		return this.restApiService.get<ProfileModel>('/profile'); // current-user
	}

	public getDevelopers(): Observable<DeveloperModel[]> {
		return this.restApiService.get<DeveloperModel[]>('/developers');
	}

	public getBooks(): Observable<BookModel[]> {
		return this.restApiService.get<BookModel[]>('/books');
	}

	public getBulletins(): Observable<BulletinModel[]> {
		return this.restApiService.get<BulletinModel[]>('/bulletins');
	}

	public getGuidelines(): Observable<GuidelineModel[]> {
		return this.restApiService.get<GuidelineModel[]>('/guidelines');
	}

	public getPlaylists(): Observable<PlaylistModel[]> {
		return this.restApiService.get<PlaylistModel[]>('/playlists');
	}

	public getEvents(min: string, max: string): Observable<EventModel[]> {
		return this.restApiService.get<EventModel[]>(`/events?min=${min}&max=${max}&state=${ContentStateKind.PUBLISHED}`);
	}

	public getDraftEvents(min: string, max: string): Observable<EventModel[]> {
		return this.restApiService.get<EventModel[]>(`/events?min=${min}&max=${max}&state=${ContentStateKind.DRAFT}`);
	}

	public getSatsangs(min: string, max: string): Observable<SatsangModel[]> {
		return this.restApiService.get<SatsangModel[]>(`/satsangs?min=${min}&max=${max}&state=${ContentStateKind.PUBLISHED}`);
	}

	public getDraftSatsangs(min: string, max: string): Observable<SatsangModel[]> {
		return this.restApiService.get<SatsangModel[]>(`/satsangs?min=${min}&max=${max}&state=${ContentStateKind.DRAFT}`);
	}

	public getBookDownloadUri(id: string): string {
		return this.restApiService.getUri(`/books/${id}/download`);
	}

	public getBookThumbnailUri(id: string): string {
		return this.restApiService.getUri(`/books/${id}/thumbnail`);
	}

	public postEvent(model: EventModel): Observable<EventModel> {
		return this.restApiService.post<EventModel>('/events', model);
	}

	public postSatsang(model: SatsangModel): Observable<SatsangModel> {
		return this.restApiService.post<SatsangModel>('/satsangs', model);
	}

	public patchPublishEvent(id: string): Observable<PatchResponseModel> {
		return this.restApiService.patch<PatchResponseModel>(`events/${id}/publish`);
	}

	public patchPublishSatsang(id: string): Observable<PatchResponseModel> {
		return this.restApiService.patch<PatchResponseModel>(`satsangs/${id}/publish`);
	}
}