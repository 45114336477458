import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bulletin-view-page',
	templateUrl: './bulletin-view.page.html',
	styleUrls: ['./bulletin-view.page.scss']
})
export class BulletinViewPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}