export class UriModel {

	private static readonly slash: string = "/";

	readonly trailingSlashNo: string;
	readonly trailingSlashYes: string;

	constructor(path: string | undefined) {
		if (path) {
			while (path.endsWith(UriModel.slash)) {
				path = path.substring(0, path.length - 1);
			}

			this.trailingSlashNo = path;
			this.trailingSlashYes = path + UriModel.slash;
		} else {
			throw new Error("undefined-path");
		}
	}

	public append(path: string): UriModel {
		while (path.startsWith(UriModel.slash)) {
			path = path.substring(1);
		}

		return new UriModel(this.trailingSlashNo + UriModel.slash + path);
	}
}