import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

//import { environment } from './environments/environment';
//if (environment.production) {
//enableProdMode();
//}

let hostname: string = window.location.hostname.toLowerCase();
if (hostname.startsWith('www.')) {
	hostname = hostname.substr(4);
}

console.log('hostname => ' + hostname);

if (hostname.indexOf('localhost') === 0) {
	console.log('enableProdMode => FALSE');
} else {
	console.log('enableProdMode => TRUE');
	enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err: any) => console.error(err));
});
