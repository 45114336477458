import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-guideline-view-page',
	templateUrl: './guideline-view.page.html',
	styleUrls: ['./guideline-view.page.scss']
})
export class GuidelineViewPage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}