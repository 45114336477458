import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppRoutes } from '../../routes/app-routes'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public disclaimerPopup: boolean = false;

	constructor(public translate: TranslateService) {
	}

	ngOnInit(): void {
	}

	public get appRoutes(): typeof AppRoutes {
		return AppRoutes;
	}
}