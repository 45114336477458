import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-guideline-update-page',
	templateUrl: './guideline-update.page.html',
	styleUrls: ['./guideline-update.page.scss']
})
export class GuidelineUpdatePage implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}