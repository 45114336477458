import { Injectable } from '@angular/core';

import { RealmEndpointModel } from 'src/app/shared/models/RealmEndpointModel';
import { RealmEndpointProviderServiceBase } from './RealmEndpointProviderServiceBase';
import { UriModel } from 'src/app/shared/models/UriModel';

@Injectable({
	providedIn: 'root'
})
export class RealmEndpointProviderService extends RealmEndpointProviderServiceBase {

	protected getInternal(): RealmEndpointModel {
		return {
			//cognitoRedirectUri: new UriModel('https://blessings.' + RealmEndpointProviderServiceBase.RootDomain + '.com/auth-callback'),
			cognitoRedirectUri: new UriModel('https://www.' + RealmEndpointProviderServiceBase.RootDomain + '.com/auth-callback'),
			restApiUri: new UriModel('https://blessings-api.' + RealmEndpointProviderServiceBase.RootDomain + '.com/api'),
		};
	}
}