import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthTokenStoreService } from 'src/app/core/services/auth-token-store.service';
import { DynamicDataProviderService } from 'src/app/core/services/dynamic-data-provider.service';
import { RealmEndpointProviderService } from 'src/app/core/services/realm-endpoint-provider.service';
import { UriModel } from 'src/app/shared/models/UriModel';

@Component({
	selector: 'app-auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

	constructor(private route: ActivatedRoute,
		private authTokenStoreService: AuthTokenStoreService,
		private dynamicDataProviderService: DynamicDataProviderService,
		private realmEndpointProviderService: RealmEndpointProviderService) {
	}

	ngOnInit(): void {
		let code: string | null = this.route.snapshot.queryParamMap.get('code');

		if (code && code.length > 0) {
			let cognitoRedirectUri: UriModel = this.realmEndpointProviderService.get().cognitoRedirectUri;

			this.dynamicDataProviderService.getAuthToken(code, cognitoRedirectUri.trailingSlashYes).subscribe(t => {
				this.authTokenStoreService.set(t);
			});
		}
	}
}